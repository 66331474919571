import { Box, Typography } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useEffect, useState } from "react";
import { OEQAComment, OEQAStatus } from "tracy-types";
import { formatDate } from "tracy-utils";

import { getAssignee } from "../../helpers/getOeQaAssignee";
import EditQAModal from "./edit-qa-modal";

export type KanbanItemSavePayload = {
  oeQaStatus: OEQAStatus | null;
  qaDueDate: Date | undefined;
};
export type KanbanItemCommentPayload = {
  comment: string;
};
export type AddCommentParams = {
  payload: KanbanItemCommentPayload;
};
export type OnCommentCreated = (comment: OEQAComment) => void;
export type OnAddComment = (params: AddCommentParams) => void;
export type onSave = (payload: KanbanItemSavePayload) => void;

export type KanbanOeItemProps = {
  title: string;
  submissionDueDate?: Date;
  currentQaStatus: OEQAStatus | null | undefined;
  qaDueDate: Date;
  oeQaStatuses: OEQAStatus[];
  contact: string | undefined;
  comments: OEQAComment[];
  onSave: onSave;
  onAddComment: OnAddComment;
  isSubmitted: boolean;
};

export type OeQaFormData = {
  status: string;
  qaDueDate: Date;
};

export const KanbanOeItem: PimoReactComponent<KanbanOeItemProps> = ({
  title,
  submissionDueDate,
  currentQaStatus,
  qaDueDate,
  oeQaStatuses,
  onSave,
  onAddComment,
  contact,
  comments,
  isSubmitted,
}) => {
  const [allComments, setAllComments] = useState<OEQAComment[]>(comments);
  const [isEditOeModalOpen, setIsEditOeModalOpen] = useState(false);

  useEffect(() => {
    setAllComments(comments);
  }, [comments]);

  const handleCloseModal = () => {
    setIsEditOeModalOpen(false);
  };
  const handleOpenModal = () => {
    if (currentQaStatus || isSubmitted) {
      setIsEditOeModalOpen(true);
    }
  };

  const handleSaveAction = async (payload: KanbanItemSavePayload) => {
    try {
      await onSave(payload);
      setIsEditOeModalOpen(false);
    } catch {}
  };

  const submitComment = async (comment: string) => {
    await onAddComment({
      payload: {
        comment,
      },
    });
  };

  return (
    <>
      <div onClick={handleOpenModal}>
        <Box
          sx={{
            display: "flex",
            px: 3,
            mx: "-8px",
            mt: "-8px",
            py: 2,
            borderBottom: "1px solid rgb(225, 225, 225)",
          }}
        >
          <Typography fontSize={12}>{title}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
            my: 2,
          }}
        >
          <Typography fontSize={12}>TRACY Contact:</Typography>
          <Typography fontSize={12} textAlign={"right"}>
            {contact || "-"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: 2,
            my: 2,
          }}
        >
          <Typography fontSize={12}>Due date:</Typography>
          <Typography fontSize={12} textAlign={"right"}>
            {currentQaStatus?.name &&
            !!oeQaStatuses?.find(
              (qaStatus) => qaStatus.name === currentQaStatus.name
            )
              ? qaDueDate
                ? `${formatDate(qaDueDate)}`
                : "Not Set"
              : submissionDueDate
                ? formatDate(submissionDueDate)
                : "Not Set"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mx: 2,
            mt: 4,
            mb: 2,
          }}
        >
          <Typography fontSize={12}>Assignee:</Typography>

          <Typography
            sx={{
              backgroundColor: "#EDE6F6",
              borderRadius: "8px",
              color: "#683AB7",
              width: "auto",
              textTransform: "none",
              fontSize: "10px",
              paddingY: 0,
              paddingX: 2,
            }}
          >
            {getAssignee(
              currentQaStatus?.name as unknown as string,
              isSubmitted
            )}
          </Typography>
        </Box>
      </div>

      <EditQAModal
        oeInfoData={{
          title,
          contact,
          isSubmitted,
        }}
        isEditOeModalOpen={isEditOeModalOpen}
        comments={allComments}
        handleCloseModal={handleCloseModal}
        currentQaStatus={currentQaStatus}
        qaDueDate={qaDueDate}
        oeQaStatuses={oeQaStatuses}
        onSaveAction={handleSaveAction}
        onSubmitComment={submitComment}
      />
    </>
  );
};
