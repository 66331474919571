import {
  Add,
  DeleteOutlined,
  EditOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { PimoButton, TitleCard } from "@pimo/pimo-components";
import { useState } from "react";
import type { ManageClusterEventPayload, OECluster } from "tracy-types";

export type ClusterManagerProps = {
  oeClusters: OECluster[];
  title: string;
};

type ClusterEventName =
  | "cluster-created"
  | "cluster-updated"
  | "cluster-deleted";

export const ManageClustersSettingsPage: PimoReactComponent<
  ClusterManagerProps,
  ClusterEventName,
  ManageClusterEventPayload
> = ({ oeClusters, fireEvent, title }) => {
  const [openForm, setOpenForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openInfoAnchor, setOpenInfoAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [infoCluster, setInfoCluster] = useState<OECluster | null>(null);
  const [formData, setFormData] = useState<ManageClusterEventPayload>({
    name: "",
  });

  const handleOpenForm = () => {
    setFormData({
      name: "",
    });
    setOpenForm(true);
  };

  const handleEdit = (cluster: OECluster) => {
    if (!cluster.id) {
      return;
    }

    setFormData({
      id: cluster.id,
      name: cluster.name ?? "",
    });

    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSubmit = () => {
    if (formData.id != null) {
      fireEvent?.("cluster-updated", { ...formData });
    } else {
      fireEvent?.("cluster-created", {
        ...formData,
      });
    }
    handleCloseForm();
  };

  const handleDelete = (cluster: OECluster) => {
    setOpenDelete(true);
    setFormData({
      id: cluster.id,
      name: cluster.name ?? "",
    });
  };

  const handleConfirmDelete = () => {
    if (formData.id !== null) {
      fireEvent?.("cluster-deleted", formData);
    }
    setOpenDelete(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return (
    <Card
      sx={{
        width: "100%",
        p: 2,
        boxShadow: "none",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "white",
          padding: "15px 0",
        }}
      >
        <TitleCard
          title={title}
          endSlot={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: 2,
                }}
              >
                <PimoButton
                  text="New Cluster"
                  icon={<Add />}
                  onClick={handleOpenForm}
                />
              </Box>
            </Box>
          }
        />
      </Stack>

      {oeClusters.length === 0 && (
        <Box sx={{ display: "grid", placeItems: "center", height: "100%" }}>
          <Typography
            sx={{
              color: "#979797",
              fontSize: "30px",
              fontWeight: 500,
              display: "block",
              margin: "auto",
              marginTop: "150px",
              marginBottom: "150px",
            }}
          >
            You have not yet created any Clusters.
          </Typography>
        </Box>
      )}

      {oeClusters.length > 0 && (
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "700px",
            marginLeft: "24px",
          }}
        >
          <Table>
            <TableBody>
              {oeClusters.map((cluster: OECluster, index: number) => (
                <TableRow key={cluster.id}>
                  <TableCell sx={{ width: "10%" }}>{index + 1}</TableCell>
                  <TableCell sx={{ width: "30%" }}>
                    <strong>{cluster.name}</strong>
                  </TableCell>
                  <TableCell align="right">
                    {cluster.oes && cluster.oes.length > 0 && (
                      <IconButton
                        onClick={({ target }) => {
                          setOpenInfoAnchor(target as HTMLButtonElement);
                          setInfoCluster(cluster);
                        }}
                      >
                        <InfoOutlined />
                      </IconButton>
                    )}

                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => handleEdit(cluster)}
                    >
                      <EditOutlined />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDelete(cluster)}
                    >
                      <DeleteOutlined />
                    </IconButton>

                    {infoCluster?.id === cluster.id && (
                      <Popover
                        open={!!openInfoAnchor}
                        anchorEl={openInfoAnchor}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        onClose={() => {
                          setOpenInfoAnchor(null);
                          setInfoCluster(null);
                        }}
                      >
                        <Box sx={{ py: 1, px: 2, minWidth: "100px" }}>
                          <i>OEs associated with this Cluster: </i>
                          <br />
                          {cluster.oes.map((oe) => (
                            <Typography key={oe.id} variant="body2">
                              {oe.name}
                            </Typography>
                          ))}
                        </Box>
                      </Popover>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}

      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        fullWidth
        maxWidth="md"
        sx={{ margin: 8 }}
        PaperProps={{ sx: { padding: 2 } }}
      >
        <DialogTitle
          sx={{
            color: "#000000",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {formData.id != null ? "Edit Cluster" : "New Cluster"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <TextField
              label="Cluster Name*"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", paddingRight: 2 }}>
          <Button onClick={handleCloseForm}>CLOSE</Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            disabled={!formData.name}
            sx={{ ml: 2 }}
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this Cluster? This also deletes the
            link to the OEs that are associated with this Cluster.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmDelete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
