export const getAssignee = (status: string, isSubmitted: boolean): string => {
  const assignees: Record<string, string> = {
    Done: "None",
    "Quality Assurance": "TRACY Team",
  };

  if ((status === "Not Started" || !status) && isSubmitted) {
    return "TRACY Team";
  }

  return assignees[status] || "TRACY Contact";
};
