import {
  Button,
  Card,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useEffect, useRef, useState } from "react";

type GeneralSettingsEventNames = "group-dashboard-settings-changed";

type GeneralSettingsPayload = {
  lockGroupDashboard2024: boolean;
  lockGroupDashboard2025: boolean;
  year: number;
  dueDate: Date | undefined;
};

export type GeneralSettingsEventPayload = {
  lockGroupDashboard2024: boolean;
  lockGroupDashboard2025: boolean;
  dueDate: Date | undefined;
};

export const GeneralSettingsPage: PimoReactComponent<
  GeneralSettingsPayload,
  GeneralSettingsEventNames,
  GeneralSettingsEventPayload
> = ({
  lockGroupDashboard2024,
  lockGroupDashboard2025,
  dueDate,
  year,
  fireEvent,
}) => {
  const [formData, setFormData] = useState<{
    [key: number | string]: boolean | Date | undefined;
  }>({
    2024: lockGroupDashboard2024,
    2025: lockGroupDashboard2025,
    dueDate: dueDate,
  });

  const [initialFormData, setInitialFormData] = useState<{
    [key: number | string]: boolean | Date | undefined;
  }>({
    2024: lockGroupDashboard2024,
    2025: lockGroupDashboard2025,
    dueDate: dueDate,
  });

  const previousYearRef = useRef<number>(year);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      2024: lockGroupDashboard2024,
      2025: lockGroupDashboard2025,
    }));
    setInitialFormData({
      2024: lockGroupDashboard2024,
      2025: lockGroupDashboard2025,
    });
  }, [lockGroupDashboard2024, lockGroupDashboard2025]);

  useEffect(() => {
    if (previousYearRef.current !== year) {
      setFormData((prev) => ({
        ...prev,
        [previousYearRef.current]: initialFormData[previousYearRef.current],
      }));
      previousYearRef.current = year;
    }
  }, [year, initialFormData]);

  const hasChanges =
    formData[year] !== initialFormData[year] ||
    formData.dueDate !== initialFormData.dueDate;

  const handleSubmit = () => {
    fireEvent?.("group-dashboard-settings-changed", {
      lockGroupDashboard2024: formData[2024] as boolean,
      lockGroupDashboard2025: formData[2025] as boolean,
      dueDate: formData.dueDate as Date,
    });

    setInitialFormData((prev) => ({
      ...prev,
      [year]: formData[year],
    }));
  };

  const handleChange = (checked: boolean) => {
    setFormData((prev) => ({
      ...prev,
      [year]: checked,
    }));
  };

  const getLabel = () => {
    return `Disable Group Dashboard for all users for ${year}`;
  };

  return (
    <Card
      sx={{
        width: "100%",
        p: 2,
        boxShadow: "none",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">General Settings</Typography>
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center" mb={6}>
        <Switch
          checked={typeof formData[year] === "boolean" ? formData[year] : false}
          onChange={(e) => handleChange(e.target.checked)}
        />
        <Typography sx={{ ml: 2 }}>{getLabel()}</Typography>
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center" mb={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={formData.dueDate || null}
            label={"Due Date"}
            onChange={(event) => {
              setFormData?.({
                ...formData,
                dueDate: event as Date,
              });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Stack>

      <Stack direction="row" justifyContent="flex-end" sx={{ mt: "auto" }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          sx={{ ml: 2 }}
          disabled={!hasChanges}
        >
          SAVE
        </Button>
      </Stack>
    </Card>
  );
};
