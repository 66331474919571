import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardProps,
  Chip,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { Markdown, PimoReactComponent } from "@pimo/pimo-app-builder";
import React, { useState } from "react";

export interface SummaryTableRiskCardProps {
  title: string;
  entries: {
    label: string;
    text: string;
    colorScheme?: { chipColor: string; textColor: string };
  }[];
  cardProps?: CardProps;
  infoIcon?: { modalHeadline: string; modalText: string };
}

export const SummaryTableRiskCard: PimoReactComponent<
  SummaryTableRiskCardProps
> = ({ title, entries, cardProps = {}, infoIcon }) => {
  const theme = useTheme();
  const [isInfoIconModalOpen, setIsInfoIconModalOpen] = useState(false);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        borderRadius: "12px",
        height: "100%",
        pb: 2,
        "@media print": {
          border: "1px solid rgba(0, 0, 0, 0.12)",
        },
        ...cardProps.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: 500, fontSize: "20px" }}>
          {title}
        </Typography>
        {infoIcon && (
          <>
            <IconButton onClick={() => setIsInfoIconModalOpen(true)}>
              <InfoOutlined />
            </IconButton>
            <Dialog
              maxWidth="lg"
              open={isInfoIconModalOpen}
              onClose={() => setIsInfoIconModalOpen(false)}
              PaperProps={{ sx: { borderRadius: 5 } }}
            >
              <Box sx={{ background: theme.palette.background.paper, p: 3 }}>
                <Typography component="h2" variant="h5">
                  {infoIcon.modalHeadline}
                </Typography>
                <Divider sx={{ mt: 2, mb: 3 }} />
                <Typography component="span">
                  <Markdown>{infoIcon.modalText}</Markdown>
                </Typography>
              </Box>
              <DialogActions sx={{ p: 3 }}>
                <Button
                  variant="contained"
                  onClick={() => setIsInfoIconModalOpen(false)}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
          width: "100%",
          mb: 1,
        }}
      />
      <Box sx={{ px: 2, minHeight: 130 }}>
        {entries.map(({ label, text, colorScheme }, index) => (
          <React.Fragment key={`${label} ${index}`}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                px: 3,
                py: 1,
                cursor: "auto",
              }}
            >
              <Typography sx={{ flex: "0 0 70%" }}>{label}</Typography>
              {colorScheme ? (
                <Chip
                  label={text}
                  sx={{
                    background: colorScheme.chipColor,
                    height: 28,
                    minWidth: 130,
                    color: colorScheme.textColor,
                  }}
                />
              ) : (
                <Typography>{text}</Typography>
              )}
            </Box>
            <Divider sx={{ mx: 2 }} />
          </React.Fragment>
        ))}
      </Box>
    </Card>
  );
};

export default SummaryTableRiskCard;
