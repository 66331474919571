export const STRAPI_URL: string =
  import.meta.env.VITE_STRAPI_URL ?? "http://localhost:1337";

export const APP_INSIGHTS_CONNECTION_STRING = import.meta.env
  .VITE_APP_INSIGHTS_CONNECTION_STRING;

export const LOGO_SMALL_FILENAME: string =
  import.meta.env.VITE_LOGO_SMALL_FILE_NAME || "allianz-small.svg";

export const LOGO_FILENAME: string =
  import.meta.env.VITE_LOGO_FILE_NAME || "allianz.svg";
