import {
  Button,
  Divider,
  FormControl,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { OEQAComment, OEQAStatus } from "tracy-types";

import { getAssignee } from "../../helpers/getOeQaAssignee";
import { theme } from "../../theme";
import { CommentsSection } from "./comments-section";

interface EditQAModalProps {
  oeInfoData: {
    title: string;
    contact: string | undefined;
    isSubmitted: boolean;
  };
  comments: OEQAComment[];
  isEditOeModalOpen: boolean;
  currentQaStatus: OEQAStatus | null | undefined;
  qaDueDate: Date;
  oeQaStatuses: OEQAStatus[];
  handleCloseModal: () => void;
  onSaveAction: (payload: {
    oeQaStatus: OEQAStatus | null;
    qaDueDate: Date | undefined;
  }) => Promise<void>;
  onSubmitComment: (comment: string) => void;
}

const defaultStatus = {
  id: undefined,
  name: "Not Started",
};

export type OeQaFormData = {
  status: string;
  qaDueDate: Date;
};

const EditQAModal: React.FC<EditQAModalProps> = ({
  oeInfoData,
  comments,
  isEditOeModalOpen,
  handleCloseModal,
  currentQaStatus,
  qaDueDate,
  oeQaStatuses,
  onSaveAction,
  onSubmitComment,
}) => {
  const [assignee, setAssignee] = useState<string>(
    getAssignee(
      currentQaStatus?.name as unknown as string,
      oeInfoData.isSubmitted
    )
  );
  const [formData, setFormData] = useState<OeQaFormData>({
    status: (currentQaStatus?.name as unknown as string) || defaultStatus.name,
    qaDueDate,
  });

  const handleStatusChange = (event: SelectChangeEvent) => {
    const selectedStatus = event.target.value;
    setFormData((prev) => ({
      ...prev,
      status: selectedStatus,
    }));
  };

  const handleSaveAction = () => {
    const newStatus = oeQaStatuses.find(
      (status) => (status.name as unknown as string) === formData.status
    );

    const payload = {
      oeQaStatus: newStatus || null,
      qaDueDate: formData.qaDueDate,
    };

    onSaveAction(payload);
  };

  useEffect(() => {
    setAssignee(getAssignee(formData.status, oeInfoData.isSubmitted));
  }, [formData.status, oeInfoData.isSubmitted]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      status:
        (currentQaStatus?.name as unknown as string) || defaultStatus.name,
      qaDueDate,
    }));
  }, [qaDueDate, currentQaStatus]);

  return (
    <>
      <Modal
        open={isEditOeModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="edit-oe-modal-title"
        aria-describedby="edit-oe-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            maxHeight: "90vh",
            borderRadius: "12px",
            bgcolor: "background.paper",
            overflowY: "auto",
            p: 4,
            pt: 0,
          }}
        >
          <Typography
            id="edit-oe-modal-title"
            variant="h6"
            component="h2"
            sx={{
              mb: 1.5,
              pb: 1.5,
              position: "sticky",
              top: 0,
              background: "white",
              zIndex: 2,
              pt: 4,
            }}
          >
            {oeInfoData.title}
          </Typography>
          <Box>
            <FormControl size="small" sx={{ width: "100%", border: "none" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: 1,
                }}
              >
                <Typography fontSize={12}>TRACY Contact:</Typography>
                <Typography fontSize={12}>{oeInfoData.contact}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: 1,
                  mb: 4,
                }}
              >
                <Typography fontSize={12}>Assignee:</Typography>
                <Typography fontSize={12}>{assignee}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: 1,
                }}
              >
                <Typography fontSize={12}>Status of Assesment:</Typography>
                <Select
                  value={
                    typeof formData.status === "string"
                      ? formData.status
                      : (formData.status as OEQAStatus)?.name?.toString() ||
                        defaultStatus.name
                  }
                  sx={{
                    minWidth: "230px",
                    height: "40px",
                  }}
                  onChange={handleStatusChange}
                >
                  <MenuItem value={defaultStatus.name} key="not_started">
                    {defaultStatus.name}
                  </MenuItem>
                  {oeQaStatuses?.map((status, index) => (
                    <MenuItem value={status.name} key={index}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: 2,
                }}
              >
                <Typography fontSize={12}>Due date:</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    InputProps={{
                      style: {
                        height: "40px",
                        width: "230px",
                      },
                    }}
                    value={formData.qaDueDate}
                    label={""}
                    onChange={(event) => {
                      setFormData?.({
                        ...formData,
                        qaDueDate: event as Date,
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              gap: 2,
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={handleCloseModal}
              sx={{
                mt: 2,
                backgroundColor: theme.palette.grey[400],
                "&:hover": {
                  backgroundColor: theme.palette.grey[500],
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSaveAction}
              sx={{ mt: 2 }}
            >
              Save
            </Button>
          </Box>

          <Divider
            sx={{
              bgcolor: theme.palette.secondary.main,
              height: "1px",
              mb: 3,
              mt: 4,
              borderWidth: 0,
            }}
          />

          <CommentsSection
            comments={comments}
            onSubmitComment={onSubmitComment}
          />
        </Box>
      </Modal>
    </>
  );
};

export default EditQAModal;
