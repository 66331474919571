import { Box } from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { TitleCard } from "@pimo/pimo-components";
import { useEffect, useRef } from "react";
import { FilterData } from "tracy-types";
import { fireFilterEventWithTheProperPayloadBasedOnTrigger } from "tracy-utils";

import {
  KanbanTitleCardSearchBarSlot,
  KanbanTitleCardSearchBarSlotProps,
} from "./kanban-search-and-filter/kanban-title-card-search-bar-slot";

export type KanbanTitleCardProps = {
  title: string;
  filterData: FilterData;
  filterValues?: { oeProjects?: string[]; contacts?: string[] };
};

export const KanbanTitleCard: PimoReactComponent<
  KanbanTitleCardProps,
  "filter:clear" | "filter:apply",
  FilterData
> = ({ fireEvent, title, filterData, filterValues, ...props }) => {
  const inputTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    return () => clearTimeout(inputTimeout.current);
  }, []);

  return (
    <TitleCard
      title={title}
      endSlot={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <KanbanTitleCardSearchBarSlot
            {...(props as KanbanTitleCardSearchBarSlotProps)}
            handleSearchQueryChange={(value) => {
              clearTimeout(inputTimeout.current);
              filterData.searchQueryFilter = value;
              inputTimeout.current = setTimeout(() => {
                fireEvent?.("filter:apply", filterData);
              }, 1000);
            }}
            handleFilterChange={(event, trigger) => {
              fireFilterEventWithTheProperPayloadBasedOnTrigger(
                trigger,
                filterData,
                event,
                fireEvent
              );
            }}
            clearAllFilters={() => fireEvent?.("filter:clear")}
            filterData={filterData}
            filterValues={filterValues}
          />
        </Box>
      }
    />
  );
};
