import type { OEQAStatus } from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function fetchOeQaStatuses(): Promise<OEQAStatus[]> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff/oe-qa-status`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as OEQAStatus[];

    return json;
  } catch {
    return [];
  }
}
