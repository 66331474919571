export const TargetCyberRiskInfoText = `

As the assessment is done on scenario level, but the reporting in ORGS is on risk level, the need for summary on the overall cyber risk level arise. This is done via selecting a representative scenario. Furthermore, after assessing the residual and target cyber risks for all scenarios, the platform automatically generates a recommended summary table with all information needed to report Cyber in the overarching TRA process via ORGS.

#### Find the representative scenario for the target assessment

- Select the scenario with the maximum overall risk rating.

- If there are several scenarios with the same maximum overall risk rating, select the one with the highest probability rating out of them.

- If there are several scenarios with the same maximum overall risk rating and the same highest probability rating, select the one with the highest financial impact rating out of them.

- If there are still several scenarios with the same maximum overall risk rating, the same highest probability rating and the same highest financial impact rating, select the one with the highest reputational impact rating out of them.”

`;

export const ResidualCyberRiskInfoText = `

As the assessment is done on scenario level, but the reporting in ORGS is on risk level, the need for summary on the overall cyber risk level arise. This is done via selecting a representative scenario. Furthermore, after assessing the residual and target cyber risks for all scenarios, the platform automatically generates a recommended summary table with all information needed to report Cyber in the overarching TRA process via ORGS.

#### Find the representative scenario for the residual assessment

- Select the scenario with the maximum overall risk rating.

- If there are several scenarios with the same maximum overall risk rating, select the one with the highest probability rating out of them.

- If there are several scenarios with the same maximum overall risk rating and the same highest probability rating, select the one with the highest financial impact rating out of them.

- If there are still several scenarios with the same maximum overall risk rating, the same highest probability rating and the same highest financial impact rating, select the one with the highest reputational impact rating out of them.

`;
