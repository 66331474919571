import { App, Plugin, Route, View } from "@pimo/pimo-app-builder";
import { GridLayout, GridLayoutProps } from "@pimo/pimo-components";
import PimoKanbanBoard, {
  DeriveKanbanBoardProps,
} from "@pimo/pimo-components/src/lib/pimo-components/kanban-board/kanban-board";
import debounce from "lodash/debounce";
import {
  FE_OEStatus,
  FE_OrganizationalEntity,
  FilterData,
  OEQAStatus,
  Profile,
  ScenarioStepStatus,
} from "tracy-types";
import { isReadonly } from "tracy-utils";

import { TracyAppState } from "../../app";
import {
  AddCommentParams,
  KanbanItemCommentPayload,
  KanbanItemSavePayload,
  KanbanOeItem,
} from "../../components/kanban-oe-item/kanban-oe-item";
import { KanbanTitleCard } from "../../components/kanban-title-card";
import { APP_ROUTES } from "../../constants";
import { createOeQaComment } from "../../helpers/fetch/fetch-oe-qa-comments";
import { fetchOEQAPage } from "../../helpers/fetch/fetch-oe-qa-page";
import { fetchOeQaStatuses } from "../../helpers/fetch/fetch-oe-qa-statuses";
import { updateOEStatus } from "../../helpers/fetch/fetch-oe-status";
import { fetchGlobalSettings } from "../../helpers/fetch/global-setting";

const COLUMN_INDICES = {
  COMPLETED_SUBMITTED: 2,
  IN_PROGRESS: 1,
  OPEN: 0,
} as const;

const FIXED_BOARD_COLUMNS = [
  "Not Started",
  "In Progress",
  "Assessment submitted",
] as const;

const COLUMN_WIDTH = "270px";

type KanbanColumn = {
  title: string;
  qaStatusId?: number;
  items: OEWithStatus[];
  dueDate?: Date;
  isStatic?: boolean;
};

type QAStatus = Record<
  "Quality Assurance" | "Remediate Findings" | "Done",
  number
>;

type ReportingStatus = {
  generalSettings: ScenarioStepStatus;
  controlEnvironmentEffectiveness: ScenarioStepStatus;
  scenariosAndMitigations: ScenarioStepStatus;
  result: ScenarioStepStatus;
};

type OEWithStatus = {
  oe: FE_OrganizationalEntity;
  reportingStatus: ReportingStatus;
  oeStatus: FE_OEStatus;
  numberOfScenarios: number;
  numberOfScenariosCompleted: number;
  numberOfSteps: number;
  numberOfStepsCompleted: number;
  isTopRisk: boolean;
};

export interface OEQualityAssurancePluginState {
  oesWithStatus: OEWithStatus[];
  kanbanData: KanbanColumn[];
  submissionDueDate: Date;
  oeQaStatuses: OEQAStatus[];
  boardColumns: string[];
  isLoading: boolean;
  error: string | null;
}

export const initialOeQaPageState = {
  oesWithStatus: [],
  kanbanData: [],
  submissionDueDate: new Date(),
  oeQaStatuses: [],
};

export class OEQualityAssurancePlugin implements Plugin<TracyAppState> {
  public route?: Route;
  private view?: View<TracyAppState, GridLayoutProps>;
  private app?: App<TracyAppState>;
  #year?: number;

  /**
   * Handles state changes with debouncing to prevent excessive updates
   */
  private stateChangeHandler = debounce(() => {
    const { year } = this.app?.getAppState() ?? {};
    if (this.#year !== year) {
      this.#year = year;
      void this.fetchKanbanData();
    }
  }, 250);

  onRegister(app: App<TracyAppState>): void {
    this.app = app;
    this.initializeView();
    this.buildRoute();

    const { year } = app.getAppState();
    this.#year = year;

    this.app.on("state:changed", this.stateChangeHandler);

    this.route?.on("load", async () => {
      await this.fetchKanbanData();
    });
  }

  onUnregister(): void {
    this.app?.off(this.stateChangeHandler);
  }

  private async initializeView() {
    if (!this.app) return;

    this.view = this.app.createView({
      name: "Quality Assurance",
      layout: new GridLayout(),
    });

    this.initializeHeadline();
    await this.fetchKanbanData();
    this.initializeKanbanBoard();
  }

  private initializeHeadline(): void {
    if (!this.view) return;

    const headline = this.view.addComponent({
      component: KanbanTitleCard,
      layoutProps: { xs: 12 },
    });

    headline.mapState(({ filterState, oes, oeClusters, year }) => ({
      title: `Quality Assurance (${year})`,
      filterData: filterState ?? {
        searchQueryFilter: "",
        topRiskFilter: [],
        nameFilter: [],
        contactFilter: [],
        clusterFilter: [],
      },
      filterValues: {
        oeProjects: oes.map((oe) => oe.name),
        contacts: oes
          .flatMap((x) => x.oeStatuses)
          .filter((oe) => oe?.year === year)
          .map((x) => x?.contact ?? "")
          .filter(Boolean),
        clusters: oeClusters.map((cluster) => cluster.name),
      },
    }));

    headline.on("filter:apply", async ({ payload }) => {
      await this.handleFilterApply(payload);
    });

    headline.on("filter:clear", async () => {
      await this.handleFilterClear();
    });
  }

  private async handleFilterApply(
    payload: FilterData | undefined
  ): Promise<void> {
    try {
      const { year, boardColumns, oeQaStatuses } =
        this.app?.getAppState() ?? {};
      if (!year || !boardColumns) return;

      const qaPageData = await fetchOEQAPage(year, payload);
      if (!qaPageData) return;

      const kanbanData = this.transformOesToKanbanFormat(
        boardColumns,
        qaPageData.oesWithOEStatusForTheYear,
        oeQaStatuses
      );

      this.app?.patchAppState({
        oesWithStatus: qaPageData.oesWithOEStatusForTheYear,
        kanbanData,
        filterState: payload,
      });
    } catch (error) {
      this.handleError("Failed to apply filters", error);
    }
  }

  private async handleFilterClear(): Promise<void> {
    try {
      const { year, boardColumns } = this.app?.getAppState() ?? {};
      if (!year || !boardColumns) return;

      const qaPageData = await fetchOEQAPage(year);
      if (!qaPageData) return;

      const kanbanData = this.transformOesToKanbanFormat(
        boardColumns,
        qaPageData.oesWithOEStatusForTheYear
      );

      this.app?.patchAppState({
        oesWithStatus: qaPageData.oesWithOEStatusForTheYear,
        kanbanData,
        filterState: {
          searchQueryFilter: "",
          contactFilter: [],
          nameFilter: [],
          topRiskFilter: [],
          reportStatusFilter: [],
          clusterFilter: [],
        },
      });
    } catch (error) {
      this.handleError("Failed to clear filters", error);
    }
  }

  private buildRoute(): void {
    if (!this.app || !this.view) return;

    this.route = this.app.createRoute({
      path: APP_ROUTES.oeQualityAssurance,
      view: this.view,
    });
  }

  private initializeKanbanBoard(): void {
    if (!this.view) return;

    const kanbanBoard = new PimoKanbanBoard({
      component: KanbanOeItem,
    });

    type KanbanProps = DeriveKanbanBoardProps<typeof kanbanBoard>;
    const kanbanBoardComponent = this.view.addComponent<
      KanbanProps,
      "action:move-item",
      { item: string; newColumn: keyof QAStatus }
    >({
      component: kanbanBoard,
      layoutProps: { xs: 12 },
    });

    kanbanBoardComponent?.mapState((state) => ({
      columns: state.kanbanData.map((column, index) => ({
        columnTitle: column.title,
        isStatic: index < 2,
        columnWidth: COLUMN_WIDTH,
        kanbanItemProps: column.items.map((item: OEWithStatus) => ({
          id: `${item.oe.id}`,
          title: item.oe.name || "",
          currentQaStatus: item.oeStatus.oeQaStatus,
          qaDueDate: item.oeStatus.oeQaDueDate || new Date(),
          isSubmitted: item.oeStatus.completionDate !== null,
          submissionDueDate: state.submissionDueDate,
          oeQaStatuses: state.oeQaStatuses,
          contact: item.oeStatus.contact,
          comments: item.oeStatus.oeQaComments || [],
          onSave: async (payload: KanbanItemSavePayload) => {
            await this.handleItemSave(item, payload);
          },
          onAddComment: async ({ payload }: AddCommentParams) => {
            await this.handleAddComment(item, payload, state.userProfile);
          },
        })),
      })),
    }));

    kanbanBoardComponent?.on("action:move-item", ({ payload }) => {
      void this.handleItemMove(payload);
    });
  }

  private async handleItemSave(
    item: OEWithStatus,
    payload: KanbanItemSavePayload
  ): Promise<void> {
    if (!item.oe.id) return;

    try {
      await updateOEStatus({
        oeId: item.oe.id,
        oeStatus: {
          ...item.oeStatus,
          oeQaStatus: payload.oeQaStatus,
          oeQaDueDate: payload.qaDueDate,
        },
      });

      await this.refreshKanbanData();
    } catch (error) {
      this.handleError("Failed to save item", error);
    }
  }

  private async handleAddComment(
    item: OEWithStatus,
    payload: KanbanItemCommentPayload,
    userProfile: Profile | undefined
  ): Promise<void> {
    if (!item.oe.id) return;

    try {
      const comment = await createOeQaComment({
        payload: {
          comment: payload.comment,
          author: userProfile?.name || "User",
        },
        id: item.oe.id,
        year: item.oeStatus.year,
      });
      const oeStatuses = this.app?.getAppState().oesWithStatus;

      this.app?.patchAppState({
        oesWithStatus: oeStatuses?.map((oeItem) => {
          if (oeItem.oe.id === item.oe.id) {
            oeItem.oeStatus.oeQaComments = [
              comment,
              ...(oeItem.oeStatus.oeQaComments || []),
            ];
          }
          return oeItem;
        }),
      });

      this.refreshKanbanData();
    } catch (error) {
      this.handleError("Failed to add comment", error);
    }
  }

  private async handleItemMove(
    payload: { item: string; newColumn: keyof QAStatus } | undefined
  ): Promise<void> {
    const state = this.app?.getAppState();
    if (!payload || !state) return;

    const qaStatus = state.oeQaStatuses.find(
      (status) => (status.name as unknown as string) === payload.newColumn
    );

    const oeItemData = state.oesWithStatus.find(
      (oeData) => oeData.oe.id === Number(payload.item)
    );

    if (!oeItemData?.oe.id) return;

    try {
      await updateOEStatus({
        oeId: oeItemData.oe.id,
        oeStatus: {
          ...oeItemData.oeStatus,
          oeQaStatus: qaStatus ? { id: qaStatus.id } : null,
        },
      });

      await this.refreshKanbanData();
    } catch (error) {
      this.handleError("Failed to move item", error);
    }
  }

  private async fetchKanbanData(): Promise<void> {
    try {
      const state = this.app?.getAppState();

      const { userProfile, permissions } = state ?? {};

      if (
        !userProfile?.isAdmin ||
        !permissions?.length ||
        permissions.some(isReadonly)
      ) {
        this.app?.navigate(APP_ROUTES.oeOverview);
        return;
      }

      this.app?.patchAppState({ isLoading: true, error: null });

      if (!state?.year) {
        throw new Error("Year is not defined in the app state.");
      }

      const [oeQaStatuses, qaPageData, settings] = await Promise.all([
        fetchOeQaStatuses(),
        fetchOEQAPage(state.year),
        fetchGlobalSettings(),
      ]);

      const boardColumns = [
        ...FIXED_BOARD_COLUMNS,
        ...oeQaStatuses.map((status) => status.name as unknown as string),
      ];

      const kanbanData = this.transformOesToKanbanFormat(
        boardColumns,
        qaPageData?.oesWithOEStatusForTheYear,
        oeQaStatuses
      );

      this.app?.patchAppState({
        oesWithStatus: qaPageData?.oesWithOEStatusForTheYear,
        boardColumns,
        kanbanData,
        submissionDueDate: settings?.dueDate,
        oeQaStatuses,
        isLoading: false,
      });
    } catch (error) {
      this.handleError("Failed to load page", error);
    }
  }

  private async refreshKanbanData(): Promise<void> {
    const state = this.app?.getAppState();
    if (!state) return;

    const qaPageData = await fetchOEQAPage(state.year);

    const kanbanData = this.transformOesToKanbanFormat(
      state.boardColumns,
      qaPageData?.oesWithOEStatusForTheYear,
      state.oeQaStatuses
    );
    this.app?.patchAppState({ kanbanData });
  }

  /**
   * Transforms organizational entities data into kanban board format using array
   */
  private transformOesToKanbanFormat(
    boardColumns: string[],
    oes: OEWithStatus[] = [],
    oeQaStatuses: OEQAStatus[] = []
  ): KanbanColumn[] {
    return boardColumns.map((columnName, index) => ({
      title: columnName,
      isStatic: index < 2,
      qaStatusId: oeQaStatuses.find(
        (status) => (status.name as unknown as string) === columnName
      )?.id,
      items: oes.filter((oeWithStatus) => {
        const statusKey = this.determineStatusKey(oeWithStatus, boardColumns);
        return index === statusKey;
      }),
    }));
  }

  /**
   * Determines the status key (column index) for an organizational entity
   */
  private determineStatusKey(
    oeWithStatus: OEWithStatus,
    columns: string[]
  ): number {
    if (!oeWithStatus.oeStatus.oeQaStatus) {
      return this.determineInitialStatus(
        oeWithStatus.reportingStatus,
        Boolean(oeWithStatus.oeStatus.completionDate)
      );
    }

    const statusColumnIndex = columns.findIndex(
      (column) => oeWithStatus.oeStatus.oeQaStatus?.name?.toString() === column
    );

    return statusColumnIndex !== -1 ? statusColumnIndex : COLUMN_INDICES.OPEN;
  }

  /**
   * Determines the initial status based on reporting status and completion
   */
  private determineInitialStatus(
    reportingStatus: ReportingStatus,
    isSubmitted: boolean
  ): number {
    const statuses = Object.values(reportingStatus);

    if (statuses.every((status) => status === "completed")) {
      return isSubmitted
        ? COLUMN_INDICES.COMPLETED_SUBMITTED
        : COLUMN_INDICES.IN_PROGRESS;
    }

    return statuses.every((status) => status === "open")
      ? COLUMN_INDICES.OPEN
      : COLUMN_INDICES.IN_PROGRESS;
  }

  /**
   * Handles errors by updating the app state and logging to console
   */
  private handleError(message: string, error: unknown): void {
    const errorMessage =
      error instanceof Error ? error.message : "Unknown error occurred";
    console.error(`${message}:`, errorMessage);

    this.app?.patchAppState({
      error: `${message}: ${errorMessage}`,
      isLoading: false,
    });
  }
}
