import { Add } from "@mui/icons-material";
import {
  App,
  DeriveTableProps,
  Plugin,
  Route,
  RouterEventPayload,
  Table,
  View,
} from "@pimo/pimo-app-builder";
import {
  ChipCell,
  DateCell,
  HighlightCard,
  IdentifierBadge,
  InformationCard,
  KeyInformationCard,
  KeyInformationCardProps,
  LargeTextCard,
  TabLayout,
  TabsLayoutProps,
  TextCardCell,
  TitleCard,
} from "@pimo/pimo-components";
import { generatePath } from "react-router-dom";
import {
  type CommonRatingValue,
  type ControlEnvRatingValue,
  type EntityValidationResult,
  type FE_Mitigation,
  type FE_OEStatus,
  type FE_OrganizationalEntity,
  type FE_Scenario,
  type FinancialRatingValue,
  type OERatingResponse,
  type OEReportPage,
  type OneInTwentyYearsProbabilityRatingOptions,
  type ProbabilityRatingValue,
  type RatingField,
  type ReputationalRatingValue,
  type ScenarioStepStatus,
  TOTAL_STEPS_OF_NORMAL_ASSESSMENT,
  TOTAL_STEPS_OF_SHORTCUT_ASSESSMENT,
  type WorstScenarioRating,
} from "tracy-types";
import {
  formatDate,
  getColorSchemeAndTextForControlEnvRatingBadge,
  getColorSchemeForRating,
  getMaxRating,
  getUpdateStatus,
  groupEntriesByCommonAxisValue,
  has1In20YearsProbability,
  hasSubversion,
  hasValidOEStatus,
  isReadonlyForOEID,
  isTopRisk,
} from "tracy-utils";

import { TracyAppState } from "../../app";
import { CommentsDialog } from "../../components/oe-report/edit/comments-dialog";
import { EditEnvironmentEffectivenessDialog } from "../../components/oe-report/edit/edit-environment-effectiveness-dialog";
import { EditProbabilityDialog } from "../../components/oe-report/edit/edit-probability-dialog";
import {
  LOCKED_SECTION_DIALOG_TEXTS,
  LockedSectionDialog,
} from "../../components/oe-report/edit/locked-section-dialog";
import { EditSettingsDialog } from "../../components/oe-report/edit/settings/edit-settings-dialog";
import OEReportSectionHeader from "../../components/oe-report/oe-report-section-header";
import OEReportTitleCard from "../../components/oe-report/oe-report-title-card";
import { MitigationDetailsPopup } from "../../components/oe-report/popups/mitigation-details-popup";
import { ProbabilityCard } from "../../components/oe-report/probability-card";
import { ScenarioOverview } from "../../components/oe-report/scenario-overview";
import { CyberRiskAssessmentTableContainer } from "../../components/oe-report/scenario-overview-table-container";
import {
  StatusOfReportingCard,
  type StatusOfReportingCardProps,
} from "../../components/oe-report/status-of-reporting-card";
import SummaryTableRiskCard from "../../components/oe-report/summary-table-risk";
import TextCard from "../../components/oe-report/text-card";
import { RiskMatrix } from "../../components/risk-matrix/risk-matrix";
import { ShortcutAssessmentDialog } from "../../components/shortcut-assessment-dialog/shortcut-assessment-dialog";
import { PercentageCompletedCell } from "../../components/table/percentage-completed-cell";
import { ProbabilityDescription } from "../../config/probability-description";
import {
  OEReportProcessDescription2024,
  OEReportProcessDescription2025,
} from "../../config/process-description";
import { RATING_LABEL } from "../../config/rating-label";
import {
  ResidualCyberRiskInfoText,
  TargetCyberRiskInfoText,
} from "../../config/residual-target-descriptions";
import { APP_ROUTES, STATUS_COLORS } from "../../constants";
import { addScenario } from "../../helpers/add-scenario";
import { updateControlEnvEffectiveness } from "../../helpers/fetch/fetch-control-envs-effectiveness";
import {
  fetchDeleteMitigationAction,
  fetchUpdateMitigationAction,
} from "../../helpers/fetch/fetch-mitigation-action";
import {
  fetchOEProbabilityRating,
  updateOEProbabilityRating,
} from "../../helpers/fetch/fetch-oe-probability-rating";
import { fetchOERatings } from "../../helpers/fetch/fetch-oe-ratings";
import { fetchOEReportPage } from "../../helpers/fetch/fetch-oe-report-page";
import {
  createOEStatus,
  updateOEStatus,
} from "../../helpers/fetch/fetch-oe-status";
import {
  createOEStatusComment,
  fetchOEStatusComments,
} from "../../helpers/fetch/fetch-oe-status-comments";
import { fetchPermissions } from "../../helpers/fetch/fetch-permissions";
import { fetchScenariosFormValues } from "../../helpers/fetch/fetch-scenarios-form-values";
import { postShortcutAssessment } from "../../helpers/fetch/fetch-shortcut-assessment";
import {
  generateFilteredRiskEntries,
  generateRiskTableEntries,
  getRepImpactAndProbRatings,
} from "../../helpers/generate-objects/generate-risk-table-entries";
import { getSubversionTitle } from "../../helpers/get-subversion-title";
import { getLatestSteps } from "../../helpers/local-storage/get-latest-steps";
import { LatestStepParams } from "../../helpers/local-storage/types";
import {
  filterScenariosWithMitigationBeforeAndAfterRemoval,
  getMitigationWarningTest,
} from "../../helpers/mitigation-warning-helper";
import { generateShortSummary } from "../../helpers/xlsx/generate-short-summary";

export type OEReportPluginState = {
  currentOEReportPage?: OEReportPage;
  currentOERatings?: OERatingResponse;
};

export class OEReportPlugin
  implements
    Plugin<
      TracyAppState,
      OEReportPluginState,
      "oeId" | "viewname" | "scenarioId"
    >
{
  route?: Route<"oeId" | "viewname" | "scenarioId", TracyAppState>;
  private tabbedView?: View<TracyAppState, TabsLayoutProps>;

  onRegister(app: App<TracyAppState>): void {
    const tabs = [
      {
        name: "Tracy Assessment",
        path: `${APP_ROUTES.oeReport}/assessment`,
        viewname: "assessment" as const,
      },
      {
        name: "OE Dashboard",
        path: `${APP_ROUTES.oeReport}/oe-dashboard`,
        viewname: "oe-dashboard" as const,
      },
    ];

    this.tabbedView = app.createView({
      name: "OE Report Tabbed View",
      layout: new TabLayout(tabs, 1, 1, []),
    });

    this.route = app.createRoute<"oeId" | "viewname" | "scenarioId">({
      path: `${APP_ROUTES.oeReport}/:viewname`,
      view: this.tabbedView,
    });

    this.route.on("load", async (event) => {
      const { oeId, viewname } = event.payload?.parameters ?? {};
      if (!oeId) return;

      await this.fetchOEReportPage(app, oeId);

      if (!viewname) {
        app.navigate(
          generatePath(`${APP_ROUTES.oeReport}/assessment`, {
            oeId: String(oeId),
          })
        );
      }
    });

    this.buildAssessmentTab(app, this.tabbedView);

    this.buildOEDashboardTab(this.tabbedView);
  }

  private buildAssessmentTab(
    app: App<TracyAppState>,
    parentView: View<TracyAppState, TabsLayoutProps>
  ) {
    const title = parentView.addComponent({
      component: OEReportTitleCard,
      layoutProps: { xs: 12, viewname: "assessment" },
    });

    title.mapState(({ year, currentOEReportPage, permissions }) => {
      const oeStatus = currentOEReportPage?.oeStatus;
      const isLocked = oeStatus?.isLocked;
      const statuses = Object.values(currentOEReportPage?.reportingStatus ?? {})
        .map((status) => status)
        .filter(Boolean);
      const updateStatus = getUpdateStatus(statuses);

      return {
        title: `TRACY Report - ${
          currentOEReportPage?.oe?.name ?? ""
        } (${year})`,
        updateStatus,
        processDescription:
          year === 2024
            ? OEReportProcessDescription2024
            : OEReportProcessDescription2025,
        completionDate: formatDate(
          currentOEReportPage?.oeStatus?.completionDate ?? ""
        ),
        isSubmitReportButtonEnabled:
          updateStatus === "up-to-date" &&
          !isLocked &&
          !isReadonlyForOEID(permissions, currentOEReportPage?.oe?.id ?? 0),
        isLocked,
      };
    });

    title.on("submit-report", async () => {
      const state = app.getAppState();
      const oeId = state?.currentOEReportPage?.oe.id;

      if (oeId == null) {
        return;
      }

      await updateOEStatus({
        oeId,
        oeStatus: {
          ...state?.currentOEReportPage?.oeStatus,
          completionDate: formatDate(new Date()),
          isLocked: true,
        },
      });
      app.patchAppState({
        snackbar: {
          message: "All changes have been saved.",
          severity: "success",
          open: true,
          type: "snackbar",
        },
      });
      await this.fetchOEReportPage(app, oeId);
    });

    const generalSettingsSectionHeadline = parentView.addComponent({
      component: OEReportSectionHeader<"edit">,
      layoutProps: { xs: 12, viewname: "assessment" },
    });

    generalSettingsSectionHeadline.mapState(
      ({ currentOEReportPage, permissions }) => {
        const isLocked = currentOEReportPage?.oeStatus?.isLocked;
        return {
          title: "1. General Parameters and Introduction",
          updateStatus:
            currentOEReportPage?.reportingStatus?.generalSettings ?? "open",
          buttons: [
            {
              title: "Edit",
              event: "edit",
              isLocked:
                isLocked ||
                isReadonlyForOEID(
                  permissions,
                  currentOEReportPage?.oe?.id ?? 0
                ),
              showTooltip: isLocked,
            },
          ],
        };
      }
    );

    generalSettingsSectionHeadline.on("edit", () => {
      app.navigate(
        generatePath(APP_ROUTES.oeReportEditSettings, {
          oeId: `${app.getAppState().currentOEReportPage?.oe?.id ?? ""}`,
        })
      );
    });

    const settingsCard = parentView.addComponent({
      component: KeyInformationCard,
      layoutProps: { xs: 12, sm: 4, viewname: "assessment" },
    });

    settingsCard.mapState(({ currentOEReportPage, year }) => {
      return {
        title: `Settings for ${currentOEReportPage?.oe?.name ?? ""}`,
        entries: [
          {
            label: "Operating Profit",
            value: currentOEReportPage?.oeStatus?.operatingProfit
              ? [
                  parseInt(
                    currentOEReportPage?.oeStatus?.operatingProfit
                  ).toLocaleString(),
                  "EUR",
                ].join(" ")
              : "N/A",
          },
          hasSubversion(year) && {
            label: "Subversion for Technical Vulnerability Exploitation",
            value: getSubversionTitle(
              currentOEReportPage?.oeStatus
                ?.subversionForTechnicalVulnerabilityExploitation ?? ""
            ),
          },
          {
            label: "Contact Person",
            value: currentOEReportPage?.oeStatus?.contact ?? "",
          },
        ].filter(Boolean) as KeyInformationCardProps["entries"],
      };
    });

    const statusOfReportingCard = parentView.addComponent({
      component: StatusOfReportingCard,
      layoutProps: { xs: 12, sm: 4, viewname: "assessment" },
    });

    statusOfReportingCard.mapState(
      ({ currentOEReportPage, currentOEStatusComments, year }) => {
        const has1In20YearsProbabilityFeatureEnabled =
          has1In20YearsProbability(year);

        return {
          title: "Status of Assessment",
          entries: [
            {
              label: "General Settings",
              linkTo: "general-settings",
              updateStatus:
                currentOEReportPage?.reportingStatus?.generalSettings ?? "open",
            },
            {
              label: "Control Environment Effect.",
              linkTo: "control-env",
              updateStatus:
                currentOEReportPage?.reportingStatus
                  ?.controlEnvironmentEffectiveness ?? "open",
            },
            {
              label: "Cyber Risk Scenarios & Mitigations",
              linkTo: "scenarios",
              updateStatus:
                currentOEReportPage?.reportingStatus?.scenariosAndMitigations ??
                "open",
            },
            {
              label: has1In20YearsProbabilityFeatureEnabled
                ? "Summary & Expert Judgement"
                : "Result & Expert Judgement",
              linkTo: "result",
              updateStatus:
                currentOEReportPage?.reportingStatus?.result ?? "open",
            },
            has1In20YearsProbabilityFeatureEnabled && {
              label: "1 in 20 years probability",
              linkTo: "1-in-20-years-probability",
              updateStatus:
                currentOEReportPage?.reportingStatus?.probability ?? "open",
            },
          ].filter(Boolean) as StatusOfReportingCardProps["entries"],
          button: {
            label: `Add Team Comments (${currentOEStatusComments?.length ?? 0})`,
            onClick: () => {
              app.navigate(
                generatePath(APP_ROUTES.oeReportComments, {
                  oeId: `${currentOEReportPage?.oe?.id ?? ""}`,
                  year: `${year}`,
                })
              );
            },
          },
        };
      }
    );

    const introductionCard = parentView.addComponent({
      component: LargeTextCard,
      layoutProps: { xs: 12, sm: 4, viewname: "assessment" },
    });

    introductionCard.mapState(() => ({
      cardTitle: "Introduction",
      textContent: `The **key goal** of **Top Risk Assessment Cyber** (TRACY) is to properly assess the cyber risk of an OE and to identify whether it is considered to be top risk. TRACY is done by using a simplified 10-step approach based on the Allianz Standard for Top Risk Assessment (ASTRA). The key parameters for the assessment of the OE’s cyber risk are the probability rating, financial impact and reputational impact. The final judgement if cyber shall be considered a top risk for TRACY (and top risk assessment in general) is made by an expert.

On the [Connect page](https://allianzms.sharepoint.com/sites/DE1890-connect-azse-group-technology/SitePages/TRACY.aspx) you will find **more information and detailed guidance** on TRACY, including the TRACY guideline, the TRACY cyber risk scenarios as well as a mapping of Allianz IT & IS Controls to the scenarios.

      `,
      // explitily set maxHeight ridiculously high so text does not have to be scrolled
      maxHeight: 10_000,
      cardProps: {
        sx: {
          background: "#003781",
          color: "#ffffff",
          a: { color: "#fff" },
        },
      },
    }));

    const controlEnvironmentEffectivenessSectionHeadline =
      parentView.addComponent({
        component: OEReportSectionHeader<"edit">,
        layoutProps: { xs: 12, viewname: "assessment" },
      });

    controlEnvironmentEffectivenessSectionHeadline.mapState(
      ({ currentOEReportPage, permissions }) => {
        let overallRating = 0;
        if (currentOEReportPage?.controlEnvironmentEffectivenessEntries) {
          overallRating =
            getMaxRating(
              currentOEReportPage?.controlEnvironmentEffectivenessEntries.map(
                (entry) => entry.rating as RatingField
              )
            ) ?? 0;
        }

        const isLocked = currentOEReportPage?.oeStatus?.isLocked;

        return {
          title: "2. Control Environment Effectiveness",
          componentId: "control-env",
          chip: {
            title:
              overallRating !== 0
                ? `Rating: ${
                    RATING_LABEL.ControlEnv[overallRating as CommonRatingValue]
                  } = ${overallRating}`
                : "Rating: N/A",
            colorScheme: getColorSchemeForRating(
              overallRating as CommonRatingValue
            ).colorScheme,
          },
          updateStatus:
            currentOEReportPage?.reportingStatus
              ?.controlEnvironmentEffectiveness ?? "open",
          buttons: [
            {
              title: "Edit",
              event: "edit",
              isLocked:
                isLocked ||
                isReadonlyForOEID(
                  permissions,
                  currentOEReportPage?.oe?.id ?? 0
                ),
              showTooltip: isLocked,
            },
          ],
        };
      }
    );

    controlEnvironmentEffectivenessSectionHeadline.on("edit", () => {
      app.navigate(
        generatePath(APP_ROUTES.oeReportEditEnvEffectiveness, {
          oeId: `${app.getAppState().currentOEReportPage?.oe?.id ?? ""}`,
        })
      );
    });

    const controlEnvironmentEffectivenessTable = new Table(
      [
        { component: IdentifierBadge },
        { component: TextCardCell },
        { component: TextCardCell },
        { component: TextCardCell },
        { component: TextCardCell },
      ],
      "report"
    );

    const controlEnvironmentEffectivenessTableComponent =
      parentView.addComponent<
        DeriveTableProps<typeof controlEnvironmentEffectivenessTable>,
        unknown,
        unknown
      >({
        component: controlEnvironmentEffectivenessTable,
        layoutProps: {
          xs: 12,
          viewname: "assessment",
        },
      });

    controlEnvironmentEffectivenessTableComponent.mapState(
      ({ currentOEReportPage }) => ({
        tableHeaderEntries: [
          "ID",
          "Dimension",
          "Rating",
          "Description for Rating",
          "Comment on Rating",
        ],
        data:
          currentOEReportPage?.controlEnvironmentEffectivenessEntries?.map(
            (entry, index) => ({
              columnProps: [
                {
                  children: index + 1,
                },
                {
                  header: "",
                  body: entry.subgroup,
                },
                {
                  header: "",
                  body: `${entry.rating ?? "not set"}`,
                },
                {
                  header: "",
                  body: RATING_LABEL.ControlEnv[entry.rating] ?? "not set",
                },
                {
                  header: "",
                  body: entry.comment ?? "not set",
                },
              ],
              rowProps: {},
            })
          ) ?? [],
      })
    );

    const cyberRiskScenariosMitigationsSectionHeadline =
      parentView.addComponent({
        component: OEReportSectionHeader<"edit" | "addScenario">,
        layoutProps: { xs: 12, viewname: "assessment" },
      });

    cyberRiskScenariosMitigationsSectionHeadline.mapState(
      ({ currentOEReportPage, permissions }) => {
        const isLocked = currentOEReportPage?.oeStatus?.isLocked;
        const hasShortcutAssessment =
          currentOEReportPage?.oe?.hasShortcutAssessment;

        return {
          title: "3. Cyber Risk Scenarios and Mitigations",
          componentId: "scenarios",
          updateStatus:
            currentOEReportPage?.reportingStatus?.scenariosAndMitigations ??
            "open",
          buttons: [
            {
              title: "Scenario",
              icon: <Add />,
              event: "addScenario",
              isLocked:
                hasShortcutAssessment ||
                isLocked ||
                isReadonlyForOEID(
                  permissions,
                  currentOEReportPage?.oe?.id ?? 0
                ),
              showTooltip: isLocked,
            },
            {
              title: "Edit",
              event: "edit",
              isLocked:
                hasShortcutAssessment ||
                isLocked ||
                isReadonlyForOEID(
                  permissions,
                  currentOEReportPage?.oe?.id ?? 0
                ),
              showTooltip: isLocked,
            },
          ],
        };
      }
    );

    cyberRiskScenariosMitigationsSectionHeadline.on("edit", () => {
      const { currentOEReportPage } = app.getAppState();
      const oe = currentOEReportPage?.oe;
      const scenario = currentOEReportPage?.scenarios?.[0];
      const latestSteps = getLatestSteps();

      function getLatestStepForOE(): LatestStepParams {
        const latestStep = latestSteps?.[oe?.id ?? 1]?.latestStep;
        if (latestStep) {
          return { ...latestStep, oeID: String(oe?.id ?? 1) };
        }
        return {
          oeID: String(oe?.id ?? 1),
          scenarioID: String(scenario?.id ?? 1),
          step: "step-0",
        };
      }

      const latestStep = getLatestStepForOE();
      app.navigate(
        generatePath(`${APP_ROUTES.oeScenarios}/*`, {
          oeId: latestStep.oeID,
          scenarioId: latestStep.scenarioID,
          "*": latestStep.step,
        })
      );
    });

    cyberRiskScenariosMitigationsSectionHeadline.on("addScenario", async () =>
      addScenario(
        app,
        () =>
          void this.fetchOEReportPage(
            app,
            app.getAppState().currentOEReportPage?.oe.id ?? 0
          )
      )
    );

    const cyberRiskScenariosTable = new Table(
      [
        { component: IdentifierBadge },
        { component: TextCardCell },
        { component: TextCardCell },
        { component: PercentageCompletedCell },
        { component: TextCardCell },
      ],
      "report"
    );

    const cyberRiskScenariosTableComponent = parentView.addComponent<
      DeriveTableProps<typeof cyberRiskScenariosTable>,
      unknown,
      unknown
    >({
      component: cyberRiskScenariosTable,
      layoutProps: {
        xs: 12,
        viewname: "assessment",
      },
    });

    cyberRiskScenariosTableComponent.mapState(({ currentOEReportPage }) => {
      const hasShortcutAssessment =
        currentOEReportPage?.oe?.hasShortcutAssessment;
      const latestSteps = getLatestSteps();
      const latestStepsByOE =
        latestSteps?.[String(currentOEReportPage?.oe?.id ?? 1)];
      const numberOfSteps = hasShortcutAssessment
        ? TOTAL_STEPS_OF_SHORTCUT_ASSESSMENT
        : TOTAL_STEPS_OF_NORMAL_ASSESSMENT;

      return {
        tableHeaderEntries: [
          "ID",
          "Scenario",
          "Short Description",
          "Completed Steps",
          "Mitigation",
        ],
        data:
          currentOEReportPage?.scenarios?.map((scenario, index) => ({
            columnProps: [
              {
                children: index + 1,
              },
              {
                header: "",
                body: scenario.name,
              },
              {
                header: "",
                body: scenario.shortDescription?.content ?? "",
              },
              {
                label: `${scenario.completedSteps} / ${numberOfSteps}`,
                percentage: Math.round(
                  (scenario.completedSteps / numberOfSteps) * 100
                ),
                colors: {
                  remaining: "#F5F5F5",
                  completed: STATUS_COLORS[scenario.progress.total ?? "open"],
                },
              },
              {
                header: "",
                body: scenario?.mitigations?.length > 0 ? "Yes" : "No",
              },
            ],
            rowProps: {
              onClick: () => {
                if (hasShortcutAssessment) {
                  return app.navigate(
                    generatePath(APP_ROUTES.oeReportShortCutAssessment, {
                      oeId: String(currentOEReportPage?.oe?.id ?? 1),
                      scenarioId: String(scenario?.id ?? 1),
                    })
                  );
                }

                function getLatestStepForScenario(): LatestStepParams {
                  const latestStep =
                    latestStepsByOE?.scenarios?.[String(scenario?.id ?? 1)];
                  if (latestStep) {
                    return {
                      ...latestStep,
                      oeID: String(currentOEReportPage?.oe?.id ?? 1),
                      scenarioID: String(scenario?.id ?? 1),
                    };
                  }
                  return {
                    oeID: String(currentOEReportPage?.oe?.id ?? 1),
                    scenarioID: String(scenario?.id ?? 1),
                    step: "step-0",
                  };
                }

                const latestStep = getLatestStepForScenario();
                app.navigate(
                  generatePath(`${APP_ROUTES.oeScenarios}/*`, {
                    oeId: latestStep.oeID,
                    scenarioId: latestStep.scenarioID,
                    "*": latestStep.step,
                  })
                );
              },
              style: {
                cursor: hasShortcutAssessment ? "inherit" : "pointer",
              },
            },
          })) ?? [],
      };
    });

    const mitigationsTable = new Table(
      [
        { component: IdentifierBadge },
        { component: TextCardCell },
        { component: TextCardCell },
        { component: TextCardCell },
        { component: DateCell },
        { component: TextCardCell },
      ],
      "report"
    );

    const mitigationsTableComponent = parentView.addComponent<
      DeriveTableProps<typeof mitigationsTable>,
      unknown,
      unknown
    >({
      component: mitigationsTable,
      layoutProps: {
        xs: 12,
        viewname: "assessment",
      },
    });

    mitigationsTableComponent.mapState(({ currentOEReportPage }) => ({
      tableHeaderEntries: [
        "ID",
        "Mitigation",
        "Scenario Addressed",
        "Owner",
        "Completion Date",
        "Source",
      ],
      data:
        currentOEReportPage?.mitigations?.map((mitigation, index) => ({
          columnProps: [
            {
              children: index + 1,
            },
            {
              body: mitigation.projectTitle,
              header: "",
            },
            {
              header: "",
              body: (mitigation.scenarios ?? [])
                .map((scenario) => scenario.name)
                .join(", "),
            },
            {
              header: "",
              body: mitigation.projectOwner ?? "",
            },
            {
              header: "",
              date: mitigation.completionDate ?? "",
            },
            {
              header: "",
              body: mitigation.source ?? "",
            },
          ],
          rowProps: {
            onClick: () => onMitigationPopupOpen(mitigation),
            style: {
              cursor: "pointer",
            },
          },
        })) ?? [],
    }));

    const mitigationDetailsPopup = parentView.addComponent({
      component: MitigationDetailsPopup,
      layoutProps: { viewname: "assessment" },
    });

    mitigationDetailsPopup.mapState((state) => {
      return {
        oeReportPage: state.currentOEReportPage ?? ({} as OEReportPage),
        open: state.currentOEReportPage?.mitigationDetailsPopupOpen,
        cardTitle: `Details Mitigation: ${
          state.currentOEReportPage?.mitigationDetailsPopupContent
            ?.projectTitle ?? ""
        }`,
        mitigation: state.currentOEReportPage?.mitigationDetailsPopupContent,
        scenarios: state.currentOEReportPage?.scenarios,
        locked: state.currentOEReportPage?.oeStatus?.isLocked,
      };
    });

    const onMitigationPopupOpen = (payload: FE_Mitigation) => {
      const { currentOEReportPage } = app?.getAppState() ?? {};
      if (!payload || !currentOEReportPage) {
        return;
      }

      app?.patchAppState({
        currentOEReportPage: {
          ...currentOEReportPage,
          mitigationDetailsPopupContent: payload,
          mitigationDetailsPopupOpen: true,
        },
      });
    };

    mitigationDetailsPopup.on("popup:close", () => {
      const { currentOEReportPage } = app?.getAppState() ?? {};
      if (!currentOEReportPage) return;

      app?.patchAppState({
        currentOEReportPage: {
          ...currentOEReportPage,
          mitigationDetailsPopupOpen: false,
        },
      });
    });

    mitigationDetailsPopup.on("popup:save-and-close", async ({ payload }) => {
      const { currentOEReportPage } = app?.getAppState() ?? {};
      const { oe, scenarios = [] } = currentOEReportPage ?? {};
      const oeId = oe?.id;

      if (!currentOEReportPage || oeId == null || !payload || !payload.id) {
        return;
      }

      const [scenariosWithMitigation, scenariosWithMitigationAfterRemoval] =
        filterScenariosWithMitigationBeforeAndAfterRemoval({
          id: payload.id,
          scenarios: scenarios.filter(
            // @ts-expect-error - this is fine, playload.scenarios is the list of ids
            ({ id }) => !payload.scenarios.includes(id)
          ),
        });

      if (
        !oe?.hasShortcutAssessment &&
        scenariosWithMitigation.length &&
        !scenariosWithMitigationAfterRemoval.length
      ) {
        if (!confirm(getMitigationWarningTest(scenariosWithMitigation))) {
          return;
        }
      }

      await fetchUpdateMitigationAction(payload, oeId);

      app?.patchAppState({
        snackbar: {
          message: "All changes have been saved.",
          severity: "success",
          open: true,
          type: "snackbar",
        },
        currentOEReportPage: {
          ...currentOEReportPage,
          mitigationDetailsPopupOpen: false,
        },
      });

      void this.fetchOEReportPage(app, oeId);
    });

    mitigationDetailsPopup.on("popup:delete", async ({ payload }) => {
      const { currentOEReportPage } = app?.getAppState() ?? {};
      const { oe, scenarios = [] } = currentOEReportPage ?? {};
      const oeId = oe?.id;

      if (!payload || !payload.id || oeId == null) {
        return;
      }

      const [scenariosWithMitigation, scenariosWithMitigationAfterRemoval] =
        filterScenariosWithMitigationBeforeAndAfterRemoval({
          id: payload.id,
          scenarios,
        });

      if (
        !oe?.hasShortcutAssessment &&
        scenariosWithMitigation.length &&
        !scenariosWithMitigationAfterRemoval.length
      ) {
        if (!confirm(getMitigationWarningTest(scenariosWithMitigation))) {
          return;
        }
      } else if (!confirm("Are you sure you want to delete this mitigation?")) {
        return;
      }

      await fetchDeleteMitigationAction(payload.id, oeId);
      void this.fetchOEReportPage(app, oeId);
    });

    const resultSectionHeadline = parentView.addComponent({
      component: OEReportSectionHeader<"editResult" | "downloadResult">,
      layoutProps: { xs: 12, viewname: "assessment" },
    });

    resultSectionHeadline.mapState(
      ({ currentOEReportPage, permissions, year }) => {
        const has1In20YearsProbabilityFeatureEnabled =
          has1In20YearsProbability(year);
        const isLocked = currentOEReportPage?.oeStatus?.isLocked;

        return {
          title: has1In20YearsProbabilityFeatureEnabled
            ? "4. Summary & Expert Judgement"
            : "4. Result & Expert Judgement",
          componentId: "result",
          updateStatus: currentOEReportPage?.reportingStatus?.result ?? "open",
          buttons: [
            {
              title: "TRACY short summary (ORGS)",
              event: "downloadResult",
              isLocked: !isLocked,
              showTooltip: false,
            },
            {
              title: "Edit",
              event: "editResult",
              isLocked:
                isLocked ||
                isReadonlyForOEID(
                  permissions,
                  currentOEReportPage?.oe?.id ?? 0
                ),
              showTooltip: isLocked,
            },
          ],
        };
      }
    );

    resultSectionHeadline.on("downloadResult", async () => {
      const { currentOERatings, currentOEReportPage, year } = app.getAppState();

      const controlEnvRating = getMaxRating(
        (currentOEReportPage?.controlEnvironmentEffectivenessEntries ?? []).map(
          (cee) => cee.rating
        )
      ) as ControlEnvRatingValue;

      const worstCurrentRatings =
        currentOERatings?.worst?.current ?? ([] as WorstScenarioRating[]);
      const worstResidualRatings =
        currentOERatings?.worst?.residual ?? ([] as WorstScenarioRating[]);

      const probabilityRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.probabilityRating)
          .filter(Boolean) as RatingField[]
      ) as ProbabilityRatingValue;
      const financialRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.financialRating)
          .filter(Boolean) as FinancialRatingValue[]
      ) as FinancialRatingValue;
      const reputationalRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.reputationalRating)
          .filter(Boolean) as ReputationalRatingValue[]
      ) as ReputationalRatingValue;
      const targetScore = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.overallRating)
          .filter(Boolean) as CommonRatingValue[]
      ) as CommonRatingValue;

      await generateShortSummary({
        controlEnvRating,
        financialRating,
        probabilityRating,
        reputationalRating,
        targetScore,
        name: currentOEReportPage?.oe?.name ?? "",
        representingSource: worstCurrentRatings.map(({ name }) => name),
        year,
      });
    });

    resultSectionHeadline.on("editResult", () => {
      const { currentOEReportPage } = app.getAppState();
      const { generalSettings, scenariosAndMitigations } =
        currentOEReportPage?.reportingStatus ??
        ({} as OEReportPage["reportingStatus"]);

      const [key] =
        Object.entries({
          generalSettings,
          scenariosAndMitigations,
        }).find(([, value]) => value !== "completed") ?? [];

      if (key) {
        app.patchAppState({
          currentOEReportPageSectionLockedText:
            LOCKED_SECTION_DIALOG_TEXTS[
              key as keyof typeof LOCKED_SECTION_DIALOG_TEXTS
            ],
        });
        app.navigate(
          generatePath(APP_ROUTES.oeReportSectionLocked, {
            oeId: `${currentOEReportPage?.oe?.id ?? ""}`,
          })
        );
      } else {
        app.navigate(
          generatePath(APP_ROUTES.oeReportScenarioOverview, {
            oeId: `${app.getAppState().currentOEReportPage?.oe?.id ?? ""}`,
          })
        );
      }
    });

    const currentRiskAssesmentTable = new Table(
      [
        { component: TextCardCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
      ],
      "report"
    );

    const currentRiskAssesment = parentView.addComponent<
      DeriveTableProps<typeof currentRiskAssesmentTable>,
      unknown,
      unknown
    >({
      component: currentRiskAssesmentTable,
      layoutProps: {
        xs: 12,
        viewname: "assessment",
      },
    });

    currentRiskAssesment.mapState((state) => ({
      tableHeaderEntries: [
        "Scenario Name",
        "Fin. Impact Rating",
        "Rep. Impact Rating",
        "Impact Severity",
        "Prob. Rating",
        "Overall Rating",
        "Top Risk",
      ],
      container: CyberRiskAssessmentTableContainer,
      containerProps: { title: "Residual Cyber Risk Assessment " },
      data: generateRiskTableEntries(state, "current") ?? [],
    }));

    const residualRiskAssesmentTable = new Table(
      [
        { component: TextCardCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
      ],
      "report"
    );

    const residualRiskAssesment = parentView.addComponent<
      DeriveTableProps<typeof residualRiskAssesmentTable>,
      unknown,
      unknown
    >({
      component: residualRiskAssesmentTable,
      layoutProps: {
        xs: 12,
        viewname: "assessment",
      },
    });

    residualRiskAssesment.mapState((state) => {
      return {
        tableHeaderEntries: [
          "Scenario Name",
          "Fin. Impact Rating",
          "Rep. Impact Rating",
          "Impact Severity",
          "Prob. Rating",
          "Overall Rating",
          "Top Risk",
          "Difference Overall Rating to Residual Risk",
        ],
        container: CyberRiskAssessmentTableContainer,
        containerProps: { title: "Target Cyber Risk Assessment" },
        data: generateRiskTableEntries(state, "residual") ?? [],
      };
    });

    const currentRiskSummary = parentView.addComponent({
      component: SummaryTableRiskCard,
      layoutProps: { xs: 6, viewname: "assessment" },
    });

    currentRiskSummary.mapState((state) => {
      const worstCurrentRatings =
        state?.currentOERatings?.worst?.current ??
        ([] as WorstScenarioRating[]);

      const worstOverallRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.overallRating)
          .filter(Boolean) as RatingField[]
      );
      const worstFinancialRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.financialRating)
          .filter(Boolean) as RatingField[]
      );
      const worstReputationalRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.reputationalRating)
          .filter(Boolean) as RatingField[]
      );
      const worstProbabilityRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.probabilityRating)
          .filter(Boolean) as RatingField[]
      );

      const isTopRisk = worstCurrentRatings?.every(
        ({ rating }) => rating?.isTopRisk == null
      )
        ? "N/A"
        : worstCurrentRatings?.some(({ rating }) => rating?.isTopRisk)
          ? "Yes"
          : "No";

      const controlEnvRating = getMaxRating(
        (
          state?.currentOEReportPage?.controlEnvironmentEffectivenessEntries ??
          []
        ).map((cee) => cee.rating)
      ) as ControlEnvRatingValue;

      return {
        title: "Summary Table Residual Cyber Risk",
        infoIcon: {
          modalHeadline: "Explanation Text:",
          modalText: ResidualCyberRiskInfoText,
        },
        entries: [
          {
            label: "Top Risk",
            text: isTopRisk,
            colorScheme: {
              chipColor: isTopRisk === "N/A" ? "#C4C4C4" : "#2196F3",
              textColor: "white",
            },
          },
          {
            label: "Top Risk Scenario(s)",
            text: state.currentOERatings?.ratings?.length
              ? state.currentOERatings?.ratings?.filter(
                  ({ current }) => current.isTopRisk
                )?.length
                ? state.currentOERatings?.ratings
                    ?.filter(({ current }) => current.isTopRisk)
                    .map((scenario) => scenario.name)
                    .join(", ")
                : "No"
              : "N/A",
          },
          {
            label: "Representative Scenario",
            text: worstCurrentRatings?.length
              ? worstCurrentRatings?.map(({ name }) => name)?.join(", ")
              : "No Scenario identified",
          },
          {
            label: "Overall Rating of the Representative Scenario",
            text: worstOverallRating
              ? `${worstOverallRating} (${
                  RATING_LABEL.FinancialImpact[worstOverallRating]
                })`
              : "N/A",
            colorScheme:
              getColorSchemeForRating(worstOverallRating).colorScheme,
          },
          {
            label: "Financial Impact Rating of the Representative Scenario",
            text: worstFinancialRating
              ? `${worstFinancialRating} (${
                  RATING_LABEL.FinancialImpact[worstFinancialRating]
                })`
              : "N/A",
            colorScheme:
              getColorSchemeForRating(worstFinancialRating).colorScheme,
          },
          {
            label: "Reputational Impact Rating of the Representative Scenario",
            text: worstReputationalRating
              ? `${worstReputationalRating} (${
                  RATING_LABEL.ReputationalImpact[worstReputationalRating]
                })`
              : "N/A",
            colorScheme: getColorSchemeForRating(worstReputationalRating)
              .colorScheme,
          },
          {
            label: "Probability Rating of the Representative Scenario",
            text: worstProbabilityRating
              ? `${worstProbabilityRating} (${
                  RATING_LABEL.ProbabilityRating[worstProbabilityRating]
                })`
              : "N/A",
            colorScheme: getColorSchemeForRating(worstProbabilityRating)
              .colorScheme,
          },
          {
            label: "Control Environment Effectiveness Rating",
            text: controlEnvRating
              ? `${controlEnvRating} (${
                  RATING_LABEL.ControlEnv[controlEnvRating]
                })`
              : "N/A",
            colorScheme:
              getColorSchemeAndTextForControlEnvRatingBadge(controlEnvRating)
                .colorScheme,
          },
        ],
      };
    });

    const residualRiskSummary = parentView.addComponent({
      component: SummaryTableRiskCard,
      layoutProps: { xs: 6, viewname: "assessment" },
    });

    residualRiskSummary.mapState((state) => {
      const worstResidualRatings =
        state?.currentOERatings?.worst?.residual ??
        ([] as WorstScenarioRating[]);
      const worstOverallRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.overallRating)
          .filter(Boolean) as RatingField[]
      );
      const worstFinancialRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.financialRating)
          .filter(Boolean) as RatingField[]
      );
      const worstReputationalRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.reputationalRating)
          .filter(Boolean) as RatingField[]
      );
      const worstProbabilityRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.probabilityRating)
          .filter(Boolean) as RatingField[]
      );
      const isTopRisk = worstResidualRatings?.every(
        ({ rating }) => rating?.isTopRisk == null
      )
        ? "N/A"
        : worstResidualRatings?.some(({ rating }) => rating?.isTopRisk)
          ? "Yes"
          : "No";

      const controlEnvRating = getMaxRating(
        (
          state?.currentOEReportPage?.controlEnvironmentEffectivenessEntries ??
          []
        ).map((cee) => cee.rating)
      ) as ControlEnvRatingValue;

      return {
        title: "Summary Table Target Cyber Risk",
        infoIcon: {
          modalHeadline: "Explanation Text:",
          modalText: TargetCyberRiskInfoText,
        },
        entries: [
          {
            label: "Top Risk",
            text: isTopRisk,
            colorScheme: {
              chipColor: isTopRisk === "N/A" ? "#C4C4C4" : "#2196F3",
              textColor: "white",
            },
          },
          {
            label: "Top Risk Scenario(s)",
            text: state.currentOERatings?.ratings?.length
              ? state.currentOERatings?.ratings?.filter(
                  ({ residual }) => residual.isTopRisk
                )?.length
                ? state.currentOERatings?.ratings
                    ?.filter(({ residual }) => residual.isTopRisk)
                    .map((scenario) => scenario.name)
                    .join(", ")
                : "No"
              : "N/A",
          },
          {
            label: "Representative Scenario",
            text: worstResidualRatings?.length
              ? worstResidualRatings?.map(({ name }) => name)?.join(", ")
              : "No Scenario identified",
          },
          {
            label: "Overall Rating of the Representative Scenario",
            text: worstOverallRating
              ? `${worstOverallRating} (${
                  RATING_LABEL.FinancialImpact[worstOverallRating]
                })`
              : "N/A",
            colorScheme:
              getColorSchemeForRating(worstOverallRating).colorScheme,
          },
          {
            label: "Financial Impact Rating of the Representative Scenario",
            text: worstFinancialRating
              ? `${worstFinancialRating} (${
                  RATING_LABEL.FinancialImpact[worstFinancialRating]
                })`
              : "N/A",
            colorScheme:
              getColorSchemeForRating(worstFinancialRating).colorScheme,
          },
          {
            label: "Reputational Impact Rating of the Representative Scenario",
            text: worstReputationalRating
              ? `${worstReputationalRating} (${
                  RATING_LABEL.ReputationalImpact[worstReputationalRating]
                })`
              : "N/A",
            colorScheme: getColorSchemeForRating(worstReputationalRating)
              .colorScheme,
          },
          {
            label: "Probability Rating of the Representative Scenario",
            text: worstProbabilityRating
              ? `${worstProbabilityRating} (${
                  RATING_LABEL.ProbabilityRating[worstProbabilityRating]
                })`
              : "N/A",
            colorScheme: getColorSchemeForRating(worstProbabilityRating)
              .colorScheme,
          },
          {
            label: "Control Environment Effectiveness Rating",
            text: controlEnvRating
              ? `${controlEnvRating} (${
                  RATING_LABEL.ControlEnv[controlEnvRating]
                })`
              : "N/A",
            colorScheme:
              getColorSchemeAndTextForControlEnvRatingBadge(controlEnvRating)
                .colorScheme,
          },
        ],
      };
    });

    const topRisksCard = parentView.addComponent({
      component: TextCard<"edit">,
      layoutProps: { xs: 12, viewname: "assessment" },
    });

    topRisksCard.on("edit", () => {
      const { currentOEReportPage } = app.getAppState();
      const { generalSettings, scenariosAndMitigations } =
        currentOEReportPage?.reportingStatus ??
        ({} as OEReportPage["reportingStatus"]);

      const [key] =
        Object.entries({
          generalSettings,
          scenariosAndMitigations,
        }).find(([, value]) => value !== "completed") ?? [];

      if (key) {
        app.patchAppState({
          currentOEReportPageSectionLockedText:
            LOCKED_SECTION_DIALOG_TEXTS[
              key as keyof typeof LOCKED_SECTION_DIALOG_TEXTS
            ],
        });
        app.navigate(
          generatePath(APP_ROUTES.oeReportSectionLocked, {
            oeId: `${currentOEReportPage?.oe?.id ?? ""}`,
          })
        );
      } else {
        app.navigate(
          generatePath(APP_ROUTES.oeReportScenarioOverview, {
            oeId: `${app.getAppState().currentOEReportPage?.oe?.id ?? ""}`,
          })
        );
      }
    });

    topRisksCard.mapState(({ currentOEReportPage, permissions }) => {
      const isLocked = currentOEReportPage?.oeStatus?.isLocked;

      return {
        cardOneTitle:
          "Do you want to overrule Top Risk Result (for Residual Assessment)?",
        textOneContent:
          currentOEReportPage?.oeStatus?.overruledTopRiskComment ?? "",
        cardTwoTitle: "Expert Judgement Comment (for entire Assessment)",
        cardTwoButtons: [
          {
            text: "Edit",
            event: "edit",
            isLocked:
              isLocked ||
              isReadonlyForOEID(permissions, currentOEReportPage?.oe?.id ?? 0),
          },
        ],
        textTwoContent: currentOEReportPage?.oeStatus?.expertJudgment ?? "",
        titleProps: { fontSize: "14px", fontWeight: 500 },
      };
    });

    const probabilityHeadline = parentView.addComponent({
      component: OEReportSectionHeader<"edit">,
      layoutProps: { xs: 12, viewname: "assessment" },
    });

    probabilityHeadline.mapState(({ currentOEReportPage, permissions }) => {
      const isLocked = currentOEReportPage?.oeStatus?.isLocked;
      return {
        title: "5. 1 in 20 years probability",
        componentId: "1-in-20-years-probability",
        updateStatus:
          currentOEReportPage?.reportingStatus?.probability ?? "open",
        buttons: [
          {
            title: "Edit",
            event: "edit",
            isLocked:
              isLocked ||
              isReadonlyForOEID(permissions, currentOEReportPage?.oe?.id ?? 0),
            showTooltip: isLocked,
          },
        ],
      };
    });

    probabilityHeadline.on("edit", () => {
      const { currentOEReportPage } = app.getAppState();
      const { generalSettings, scenariosAndMitigations, result } =
        currentOEReportPage?.reportingStatus ??
        ({} as OEReportPage["reportingStatus"]);

      const [key] =
        Object.entries({
          generalSettings,
          scenariosAndMitigations,
          result,
        }).find(([, value]) => value !== "completed") ?? [];

      if (key) {
        app.patchAppState({
          currentOEReportPageSectionLockedText:
            LOCKED_SECTION_DIALOG_TEXTS["all"],
        });
        app.navigate(
          generatePath(APP_ROUTES.oeReportSectionLocked, {
            oeId: `${currentOEReportPage?.oe?.id ?? ""}`,
          })
        );
      } else {
        app.navigate(
          generatePath(APP_ROUTES.oeReportEditProbability, {
            oeId: `${app.getAppState().currentOEReportPage?.oe?.id ?? ""}`,
          })
        );
      }
    });

    const probabilityCard = parentView.addComponent({
      component: ProbabilityCard,
      layoutProps: { xs: 6, viewname: "assessment" },
    });

    probabilityCard.mapState(({ currentOEProbabilityRatingOptions }) => {
      const { financialImpact, reputationalImpact } =
        currentOEProbabilityRatingOptions ??
        ({} as OneInTwentyYearsProbabilityRatingOptions);

      return {
        rating: [
          {
            label: "Financial Impact Rating",
            value: financialImpact?.find(({ selected }) => selected)?.value,
          },
          {
            label: "Reputational Impact Rating",
            value: reputationalImpact?.find(({ selected }) => selected)?.value,
          },
        ],
        text: "What is the overall financial and reputational impact rating for your OE when considering an occurence and loss probability of 1 in 20 years?",
        title:
          "Financial and Reputational Impact Rating with fixing Probabilities",
        infoIcon: {
          modalHeadline: "Explanation Text:",
          modalText: ProbabilityDescription,
        },
        comment: currentOEProbabilityRatingOptions?.comment ?? "",
      };
    });

    const probabilityHeatMap = parentView.addComponent({
      component: RiskMatrix,
      layoutProps: { xs: 6, viewname: "assessment" },
    });

    probabilityHeatMap.mapState(({ currentOEReportPage }) => {
      const { financialImpactRating, reputationalImpactRating } =
        currentOEReportPage?.oeStatus ?? ({} as FE_OEStatus);
      const hasRequiredValues =
        financialImpactRating != null && reputationalImpactRating != null;

      return {
        entries: hasRequiredValues
          ? [
              [
                {
                  name: "",
                  xAxisField: financialImpactRating,
                  yAxisField: reputationalImpactRating as CommonRatingValue,
                },
              ],
            ]
          : [],
        title: "Heat Map (1 in 20 years probability)",
        xAxisLabel: "Financial Impact Rating",
        yAxisLabel: "Reputational Impact Rating",
      };
    });

    [probabilityHeadline, probabilityCard, probabilityHeatMap].forEach(
      (component) =>
        component.mapVisibility(({ year }) => {
          return has1In20YearsProbability(year);
        })
    );

    //  Build Possibility Dialog
    const dialogPossibilityView = app.createView({ name: "Probability" });
    const dialogPossibility = dialogPossibilityView.addComponent({
      component: EditProbabilityDialog,
    });

    dialogPossibility.on("cancel", () => {
      this.navigateBackToReport(app);
    });

    dialogPossibility.on("submit", async ({ payload }) => {
      if (!payload) return;
      const { currentOEReportPage, year } = app.getAppState();

      await updateOEProbabilityRating({
        id: currentOEReportPage?.oe?.id ?? 0,
        year,
        data: payload,
      });

      this.navigateBackToReport(app);
      app.patchAppState({
        snackbar: {
          message: "All changes have been saved.",
          severity: "success",
          open: true,
          type: "snackbar",
        },
      });
      void this.fetchOEReportPage(app, currentOEReportPage?.oe?.id ?? 0);
    });

    dialogPossibility.mapState(({ currentOEProbabilityRatingOptions }) => {
      return {
        currentOEProbabilityRatingOptions,
      };
    });

    if (!this.route) return;
    this.route
      .createChildRoute({
        path: "edit/probability",
        view: dialogPossibilityView,
        isOverlayChild: true,
      })
      .on("load", async ({ payload }) => {
        const { parameters } = payload as RouterEventPayload<"oeId">;
        const { year } = app.getAppState();

        if (!has1In20YearsProbability(year)) {
          this.navigateBackToReport(app);
          return;
        }

        if (!parameters?.oeId || !/\d/.test(parameters.oeId)) {
          app.navigate(APP_ROUTES.oeOverview);
          return;
        }

        const currentOEProbabilityRatingOptions =
          await fetchOEProbabilityRating({
            id: +parameters.oeId,
            year,
          });
        if (currentOEProbabilityRatingOptions) {
          app.patchAppState({
            currentOEProbabilityRatingOptions,
          });
        }
      });

    // Build comments dialog
    const dialogViewComments = app.createView({ name: "Comments" });
    const dialogComments = dialogViewComments.addComponent({
      component: CommentsDialog,
    });

    dialogComments.mapState(({ currentOEStatusComments }) => {
      const comments =
        currentOEStatusComments?.map(({ createdAt, ...comment }) => ({
          ...comment,
          date: createdAt ? formatDate(createdAt) : "",
        })) ?? [];

      return {
        comments,
        headline: "Comments on Status",
      };
    });

    dialogComments.on("add-comment", async ({ payload }) => {
      const { currentOEReportPage, userProfile, year } = app.getAppState();
      if (!currentOEReportPage?.oe?.id || !payload || !userProfile || !year) {
        return;
      }

      await createOEStatusComment({
        id: currentOEReportPage.oe.id,
        year,
        data: { ...payload, author: userProfile.name },
      });

      app.patchAppState({
        currentOEStatusComments: await fetchOEStatusComments({
          id: currentOEReportPage.oe.id,
          year,
        }),
      });
    });

    dialogComments.on("close", () => {
      this.navigateBackToReport(app);
    });

    dialogComments.on("fetch", async () => {
      const { currentOEReportPage, year } = app.getAppState();
      if (!currentOEReportPage?.oe?.id || !year) {
        return;
      }

      app.patchAppState({
        currentOEStatusComments: await fetchOEStatusComments({
          id: currentOEReportPage.oe.id,
          year,
        }),
      });
    });

    if (!this.route) return;
    this.route
      .createChildRoute<"oeId" | "year">({
        path: ":year/comments",
        view: dialogViewComments,
        isOverlayChild: true,
        isVisible: true,
      })
      .on("load", async (event) => {
        const { oeId } = event.payload?.parameters ?? {};
        const year = app.getAppState().year;
        if (oeId == null || year == null) return;
        await this.fetchOEReportPage(app, oeId);
      });

    // Build scenario overview
    const scenarioOverview = app.createView({ name: "Overview" });
    const scenarioOverviewDialogView = scenarioOverview.addComponent({
      component: ScenarioOverview,
    });

    scenarioOverviewDialogView.mapState((state) => {
      const { currentOERatings } = state;
      const worstCurrentRatings =
        currentOERatings?.worst?.current ?? ([] as WorstScenarioRating[]);
      const worstResidualRatings =
        currentOERatings?.worst?.residual ?? ([] as WorstScenarioRating[]);
      const worstCurrentOverallRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.overallRating)
          .filter(Boolean) as RatingField[]
      );
      const worstCurrentFinancialRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.financialRating)
          .filter(Boolean) as RatingField[]
      );
      const worstCurrentReputationalRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.reputationalRating)
          .filter(Boolean) as RatingField[]
      );
      const worstCurrentProbabilityRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.probabilityRating)
          .filter(Boolean) as RatingField[]
      );
      const worstResidualOverallRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.overallRating)
          .filter(Boolean) as RatingField[]
      );
      const worstResidualFinancialRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.financialRating)
          .filter(Boolean) as RatingField[]
      );
      const worstResidualReputationalRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.reputationalRating)
          .filter(Boolean) as RatingField[]
      );
      const worstResidualProbabilityRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.probabilityRating)
          .filter(Boolean) as RatingField[]
      );

      const {
        generalSettings,
        controlEnvironmentEffectiveness,
        scenariosAndMitigations,
        result,
      } = state.currentOEReportPage?.reportingStatus ?? {};
      const statuses = [
        generalSettings,
        controlEnvironmentEffectiveness,
        scenariosAndMitigations,
        result,
      ]
        .filter(Boolean)
        .map((x) => x) as ScenarioStepStatus[];

      return {
        status: getUpdateStatus(statuses),
        oeStatus: state.currentOEReportPage?.oeStatus,
        formValidationResult: state.formValidationResult,
        currentCyberRiskAssessment:
          currentOERatings?.ratings.map(({ current, name }) => {
            const financialRating = current?.financialRating;
            const reputationalRating = current?.reputationalRating;
            const impactSeverity = current?.impactSeverityRating;
            const probabilityRating = current?.probabilityRating;
            const overallRating = current?.overallRating;
            const topRisk = isTopRisk({ impactSeverity, probabilityRating });

            return {
              name,
              financialRating: {
                text: financialRating
                  ? `${financialRating} (${RATING_LABEL.FinancialImpact[financialRating]})`
                  : "N/A",
                ...getColorSchemeForRating(financialRating).colorScheme,
              },
              impactRating: {
                text: reputationalRating
                  ? `${reputationalRating} (${RATING_LABEL.ReputationalImpact[reputationalRating]})`
                  : "N/A",
                ...getColorSchemeForRating(reputationalRating).colorScheme,
              },
              impactSeverity: {
                text: impactSeverity
                  ? `${impactSeverity} (${RATING_LABEL.ReputationalImpact[impactSeverity]})`
                  : "N/A",
                ...getColorSchemeForRating(impactSeverity).colorScheme,
              },
              probabilityRating: {
                text: probabilityRating
                  ? `${probabilityRating} (${RATING_LABEL.ProbabilityRating[probabilityRating]})`
                  : "N/A",
                ...getColorSchemeForRating(probabilityRating).colorScheme,
              },
              overallRating: {
                text: overallRating
                  ? `${overallRating} (${RATING_LABEL.FinancialImpact[overallRating]})`
                  : "N/A",
                ...getColorSchemeForRating(overallRating).colorScheme,
              },
              topRisk: {
                text: topRisk == null ? "N/A" : topRisk ? "Yes" : "No",
                chipColor: topRisk == null ? "#C4C4C4" : "#2196F3",
                textColor: "white",
              },
            };
          }) ?? [],
        residualCyberRiskAssessment:
          currentOERatings?.ratings.map(({ delta, residual, name }) => {
            const financialRating = residual?.financialRating;
            const reputationalRating = residual?.reputationalRating;
            const impactSeverity = residual?.impactSeverityRating;
            const probabilityRating = residual?.probabilityRating;
            const overallRating = residual?.overallRating;
            const topRisk = isTopRisk({ impactSeverity, probabilityRating });

            return {
              name,
              financialRating: {
                text: financialRating
                  ? `${financialRating} (${RATING_LABEL.FinancialImpact[financialRating]})`
                  : "N/A",
                ...getColorSchemeForRating(financialRating).colorScheme,
              },
              impactRating: {
                text: reputationalRating
                  ? `${reputationalRating} (${RATING_LABEL.ReputationalImpact[reputationalRating]})`
                  : "N/A",
                ...getColorSchemeForRating(reputationalRating).colorScheme,
              },
              impactSeverity: {
                text: impactSeverity
                  ? `${impactSeverity} (${RATING_LABEL.ReputationalImpact[impactSeverity]})`
                  : "N/A",
                ...getColorSchemeForRating(impactSeverity).colorScheme,
              },
              probabilityRating: {
                text: probabilityRating
                  ? `${probabilityRating} (${RATING_LABEL.ProbabilityRating[probabilityRating]})`
                  : "N/A",
                ...getColorSchemeForRating(probabilityRating).colorScheme,
              },
              overallRating: {
                text: overallRating ? `${overallRating}` : "N/A",
                ...getColorSchemeForRating(overallRating).colorScheme,
              },
              topRisk: {
                text: topRisk == null ? "N/A" : topRisk ? "Yes" : "No",
                chipColor: topRisk == null ? "#C4C4C4" : "#2196F3",
                textColor: "white",
              },
              delta: {
                text: delta.overallRating ? `${delta.overallRating}` : "N/A",
                chipColor: "white",
                textColor: "black",
              },
            };
          }) ?? [],
        currentRiskSummaryTableEntries: (() => {
          const isTopRisk = worstCurrentRatings?.every(
            ({ rating }) => rating?.isTopRisk == null
          )
            ? "N/A"
            : worstCurrentRatings?.some(({ rating }) => rating?.isTopRisk)
              ? "Yes"
              : "No";

          return [
            {
              label: "Top Risk",
              text: isTopRisk,
              colorScheme: {
                chipColor: isTopRisk === "N/A" ? "#C4C4C4" : "#2196F3",
                textColor: "white",
              },
            },
            {
              label: "Top Risk Scenario(s)",
              text: state.currentOERatings?.ratings?.length
                ? state.currentOERatings?.ratings
                    ?.filter(({ current }) => current.isTopRisk)
                    .map((scenario) => scenario.name)
                    .join(", ")
                : "No",
            },
            {
              label: "Representative Scenario",
              text: worstCurrentRatings?.length
                ? worstCurrentRatings?.map(({ name }) => name)?.join(", ")
                : "No Scenario identified",
            },
            {
              label: "Maximum of Overall Rating",
              text: worstCurrentOverallRating
                ? `${worstCurrentOverallRating} (${
                    RATING_LABEL.FinancialImpact[worstCurrentOverallRating]
                  })`
                : "N/A",
              colorScheme: getColorSchemeForRating(worstCurrentOverallRating)
                .colorScheme,
            },
            {
              label: "Overall Financial Impact Rating",
              text: worstCurrentFinancialRating
                ? `${worstCurrentFinancialRating} (${
                    RATING_LABEL.FinancialImpact[worstCurrentFinancialRating]
                  })`
                : "N/A",
              colorScheme: getColorSchemeForRating(worstCurrentFinancialRating)
                .colorScheme,
            },
            {
              label: "Overall Reputational Impact Rating",
              text: worstCurrentReputationalRating
                ? `${worstCurrentReputationalRating} (${
                    RATING_LABEL.ReputationalImpact[
                      worstCurrentReputationalRating
                    ]
                  })`
                : "N/A",
              colorScheme: getColorSchemeForRating(
                worstCurrentReputationalRating
              ).colorScheme,
            },
            {
              label: "Overall Probability Rating",
              text: worstCurrentProbabilityRating
                ? `${worstCurrentProbabilityRating} (${
                    RATING_LABEL.ProbabilityRating[
                      worstCurrentProbabilityRating
                    ]
                  })`
                : "N/A",
              colorScheme: getColorSchemeForRating(
                worstCurrentProbabilityRating
              ).colorScheme,
            },
          ];
        })(),
        residualRiskSummaryTableEntries: (() => {
          const isTopRisk = worstResidualRatings?.every(
            ({ rating }) => rating?.isTopRisk == null
          )
            ? "N/A"
            : worstResidualRatings?.some(({ rating }) => rating?.isTopRisk)
              ? "Yes"
              : "No";

          return [
            {
              label: "Top Risk",
              text: isTopRisk,
              colorScheme: {
                chipColor: isTopRisk === "N/A" ? "#C4C4C4" : "#2196F3",
                textColor: "white",
              },
            },
            {
              label: "Top Risk Scenario(s)",
              text: state.currentOERatings?.ratings?.length
                ? state.currentOERatings?.ratings
                    ?.filter(({ residual }) => residual.isTopRisk)
                    .map((scenario) => scenario.name)
                    .join(", ")
                : "No",
            },
            {
              label: "Representative Scenario",
              text: worstResidualRatings?.length
                ? worstResidualRatings?.map(({ name }) => name)?.join(", ")
                : "No Scenario identified",
            },
            {
              label: "Maximum of Overall Rating",
              text: worstResidualOverallRating
                ? `${worstResidualOverallRating} (${
                    RATING_LABEL.FinancialImpact[worstResidualOverallRating]
                  })`
                : "N/A",
              colorScheme: getColorSchemeForRating(worstResidualOverallRating)
                .colorScheme,
            },
            {
              label: "Overall Financial Impact Rating",
              text: worstResidualFinancialRating
                ? `${worstResidualFinancialRating} (${
                    RATING_LABEL.FinancialImpact[worstResidualFinancialRating]
                  })`
                : "N/A",
              colorScheme: getColorSchemeForRating(worstResidualFinancialRating)
                .colorScheme,
            },
            {
              label: "Overall Reputational Impact Rating",
              text: worstResidualReputationalRating
                ? `${worstResidualReputationalRating} (${
                    RATING_LABEL.ReputationalImpact[
                      worstResidualReputationalRating
                    ]
                  })`
                : "N/A",
              colorScheme: getColorSchemeForRating(
                worstResidualReputationalRating
              ).colorScheme,
            },
            {
              label: "Overall Probability Rating",
              text: worstResidualProbabilityRating
                ? `${worstResidualProbabilityRating} (${
                    RATING_LABEL.ProbabilityRating[
                      worstResidualProbabilityRating
                    ]
                  })`
                : "N/A",
              colorScheme: getColorSchemeForRating(
                worstResidualProbabilityRating
              ).colorScheme,
            },
          ];
        })(),
      };
    });

    scenarioOverviewDialogView.on("submit", async ({ payload }) => {
      app.patchAppState({ formValidationResult: undefined });
      const oeId = app.getAppState().currentOEReportPage?.oe?.id;
      if (!payload || !oeId) return;

      try {
        await updateOEStatus({
          oeId,
          oeStatus: {
            id: payload.id,
            overruledTopRiskComment: payload.overruledTopRiskComment,
            expertJudgment: payload.expertJudgment,
            overruledTopRisk: payload.overruledTopRisk,
          },
        });
        this.navigateBackToReport(app);
        await this.fetchOEReportPage(app, oeId);
        app.patchAppState({
          snackbar: {
            message: "All changes have been saved.",
            severity: "success",
            open: true,
            type: "snackbar",
          },
        });
      } catch (error) {
        app.patchAppState({
          formValidationResult: error as EntityValidationResult,
        });
      }
    });

    scenarioOverviewDialogView.on("cancel", () => {
      app.patchAppState({ formValidationResult: undefined });
      this.navigateBackToReport(app);
    });

    scenarioOverviewDialogView.on("go-back", () => {
      const { currentOEReportPage } = app.getAppState();
      if (!hasValidOEStatus(currentOEReportPage?.oeStatus)) {
        app.navigate(
          generatePath(APP_ROUTES.oeReportSectionLocked, {
            oeId: String(currentOEReportPage?.oe.id ?? 1),
          })
        );
        return;
      }
      app.navigate(
        generatePath(`${APP_ROUTES.oeScenarios}/*`, {
          oeId: String(currentOEReportPage?.oe.id ?? 1),
          scenarioId: String(currentOEReportPage?.scenarios[0].id ?? 1),
          "*": "step-0",
        })
      );
    });

    if (!this.route) return;
    this.route
      .createChildRoute<"oeId">({
        path: "edit/scenario-overview",
        view: scenarioOverview,
        isOverlayChild: true,
      })
      .on("load", (event) => {
        const oeId = event.payload?.parameters?.oeId;
        if (oeId == null) return;
        void this.fetchOEReportPage(app, oeId);
      });

    // Build Control Environment Effectiveness Dialog
    const editView = app.createView({ name: "Edit Control Env Dialog" });
    const editDialog = editView.addComponent({
      component: EditEnvironmentEffectivenessDialog,
    });

    editDialog.mapState((state) => {
      const { currentOEReportPage, formValidationResult, year } = state;
      return {
        oeName: currentOEReportPage?.oe?.name,
        year,
        controlEnvironmentEffectivenessEntries:
          currentOEReportPage?.controlEnvironmentEffectivenessEntries ?? [],
        formValidationResult,
        updateStatus:
          currentOEReportPage?.reportingStatus
            ?.controlEnvironmentEffectiveness ?? "open",
      };
    });

    editDialog.on("submit", async ({ payload }) => {
      app.patchAppState({ formValidationResult: undefined });
      const oeId = app.getAppState().currentOEReportPage?.oe?.id;
      if (!payload || !oeId) return;

      try {
        await updateControlEnvEffectiveness({ ...payload, oeId });

        this.navigateBackToReport(app);
        await this.fetchOEReportPage(app, oeId);
        app.patchAppState({
          snackbar: {
            message: "All changes have been saved.",
            severity: "success",
            open: true,
            type: "snackbar",
          },
        });
      } catch (error) {
        app.patchAppState({
          formValidationResult: error as EntityValidationResult,
        });
      }
    });

    editDialog.on("cancel", () => {
      app.patchAppState({ formValidationResult: undefined });
      this.navigateBackToReport(app);
    });

    if (!this.route) return;
    this.route.createChildRoute({
      path: "edit/env-effectiveness",
      view: editView,
      isOverlayChild: true,
    });

    // Build ShortCutAssessment Dialog

    const dialogShortCutView = app.createView({ name: "ShortCutAssessment" });
    const dialogShortCut = dialogShortCutView.addComponent({
      component: ShortcutAssessmentDialog,
    });

    dialogShortCut.mapState(({ currentOEReportPage, currentScenario }) => {
      const isLocked = currentOEReportPage?.oeStatus?.isLocked;
      return {
        isLocked,
        scenario: currentScenario,
      };
    });

    dialogShortCut.on("cancel", () => {
      this.navigateBackToReport(app);
    });

    dialogShortCut.on("submit", async ({ payload }) => {
      if (!payload) return;
      const { currentOEReportPage, currentScenario, year } = app.getAppState();

      await postShortcutAssessment({
        data: payload,
        oeId: currentOEReportPage?.oe?.id ?? 0,
        scenarioId: currentScenario?.id ?? 0,
        year,
      });
      app.patchAppState({
        snackbar: {
          message: "All changes have been saved.",
          severity: "success",
          open: true,
          type: "snackbar",
        },
      });
      await this.fetchOEReportPage(app, currentOEReportPage?.oe?.id ?? 0);
      this.navigateBackToReport(app);
    });

    if (!this.route) return;
    this.route
      .createChildRoute<"oeId" | "scenarioId">({
        path: "shortcut-assessment/:scenarioId",
        view: dialogShortCutView,
        isOverlayChild: true,
      })
      .on("load", async (event) => {
        const oeId = event.payload?.parameters?.oeId;
        const scenarioId = event.payload?.parameters?.scenarioId;
        if (oeId == null || scenarioId == null) {
          return;
        }
        await this.fetchOEReportPage(app, oeId);

        const state = app.getAppState();
        const {
          currentOEReportPage: {
            oe = {} as FE_OrganizationalEntity,
            scenarios = [] as FE_Scenario[],
          } = {},
          year,
        } = state;

        if (
          Object.hasOwn(oe, "hasShortcutAssessment") &&
          !oe?.hasShortcutAssessment
        ) {
          this.navigateBackToReport(app);
        } else {
          app.patchAppState({
            currentScenario: scenarios?.find(({ id }) => id === +scenarioId),
            year,
          });
        }
      });

    // Build Locked Section Dialog

    const dialogLockedSectionView = app.createView({ name: "Locked Section" });
    const dialogSection = dialogLockedSectionView.addComponent({
      component: LockedSectionDialog,
    });
    dialogSection.mapState(({ currentOEReportPageSectionLockedText }) => {
      return {
        text: currentOEReportPageSectionLockedText ?? "",
      };
    });

    dialogSection.on("close", () => {
      this.navigateBackToReport(app);
    });

    if (!this.route) return;
    this.route
      .createChildRoute<"oeId">({
        path: "section-locked",
        view: dialogLockedSectionView,
        isOverlayChild: true,
      })
      .on("load", (event) => {
        const oeId = event.payload?.parameters?.oeId;
        if (oeId == null) return;
        void this.fetchOEReportPage(app, oeId);
      });

    // Build Settings Dialog
    const editSettingsView = app.createView({ name: "Edit OE Settings" });
    const editSettingsDialog = editSettingsView.addComponent({
      component: EditSettingsDialog,
    });

    editSettingsDialog.mapState(
      ({ currentOEReportPage, formValidationResult }) => ({
        oeName: currentOEReportPage?.oe?.name,
        year: app.getAppState().year,
        oeStatus: currentOEReportPage?.oeStatus,
        formValidationResult,
      })
    );
    editSettingsDialog.on("submit", async ({ payload }) => {
      app.patchAppState({ formValidationResult: undefined });
      const { currentOEReportPage, year } = app.getAppState();
      const oeId = currentOEReportPage?.oe?.id;

      if (!payload || !oeId) {
        return;
      }

      const {
        id,
        contact,
        operatingProfit,
        subversionForTechnicalVulnerabilityExploitation,
      } = payload;

      try {
        if (!payload.id) {
          await createOEStatus({
            oeId,
            oeStatus: {
              contact,
              operatingProfit,
              subversionForTechnicalVulnerabilityExploitation,
              year,
            },
          });
        } else {
          await updateOEStatus({
            oeId,
            oeStatus: {
              id,
              contact,
              operatingProfit,
              subversionForTechnicalVulnerabilityExploitation,
            },
          });
        }

        this.navigateBackToReport(app);
        await this.fetchOEReportPage(app, oeId);
        app.patchAppState({
          snackbar: {
            message: "All changes have been saved.",
            severity: "success",
            open: true,
            type: "snackbar",
          },
        });
      } catch (error) {
        app.patchAppState({
          formValidationResult: error as EntityValidationResult,
        });
      }
    });
    editSettingsDialog.on("cancel", () => {
      app.patchAppState({ formValidationResult: undefined });
      this.navigateBackToReport(app);
    });
    if (!this.route) return;
    this.route.createChildRoute({
      path: "edit/settings",
      view: editSettingsView,
      isOverlayChild: true,
      isVisible: true,
    });
  }

  private buildOEDashboardTab(
    parentView: View<TracyAppState, TabsLayoutProps>
  ) {
    const title = parentView.addComponent({
      component: TitleCard,
      layoutProps: { xs: 12, viewname: "oe-dashboard" },
    });

    title.mapState(({ currentOEReportPage }) => {
      return {
        title: `${currentOEReportPage?.oe?.name ?? ""} - OE Dashboard (Residual Cyber Risk Assessment)`,
      };
    });

    const oeDashboardOverviewHeadline = parentView.addComponent({
      component: OEReportSectionHeader<"edit">,
      layoutProps: { xs: 12, viewname: "oe-dashboard" },
    });

    oeDashboardOverviewHeadline.mapState(({ year }) => {
      return {
        title: `Overview ${year}`,
      };
    });

    const topRiskCard = parentView.addComponent({
      component: HighlightCard,
      layoutProps: { xs: 3, viewname: "oe-dashboard" },
    });

    topRiskCard.mapState(({ currentOERatings }) => {
      const worstCurrentRatings =
        currentOERatings?.worst?.current ?? ([] as WorstScenarioRating[]);

      const isTopRisk = worstCurrentRatings?.every(
        ({ rating }) => rating?.isTopRisk == null
      )
        ? "N/A"
        : worstCurrentRatings?.some(({ rating }) => rating?.isTopRisk)
          ? "Yes"
          : "No";

      return {
        header: "Top Risk",
        chips: [
          {
            label: isTopRisk,
            backgroundColor: isTopRisk === "N/A" ? "#C4C4C4" : "#2196F3",
            textColor: "#fff",
          },
        ],
      };
    });

    const representativeScenarioCard = parentView.addComponent({
      component: HighlightCard,
      layoutProps: { xs: 3, viewname: "oe-dashboard" },
    });

    representativeScenarioCard.mapState(({ currentOERatings }) => {
      const worstCurrentRatings =
        currentOERatings?.worst?.current ?? ([] as WorstScenarioRating[]);
      const representativeScenarioNames = worstCurrentRatings?.length
        ? worstCurrentRatings?.map(({ name }) => name)?.join(", ")
        : "No Scenario identified";

      return {
        header: "Representative Scenario",
        content: representativeScenarioNames,
        isCondensedText: representativeScenarioNames.length > 50,
      };
    });

    const probabilityRatingCard = parentView.addComponent({
      component: HighlightCard,
      layoutProps: { xs: 3, viewname: "oe-dashboard" },
    });

    probabilityRatingCard.mapState(({ currentOERatings }) => {
      const worstCurrentRatings =
        currentOERatings?.worst?.current ?? ([] as WorstScenarioRating[]);

      const worstProbabilityRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.probabilityRating)
          .filter(Boolean) as RatingField[]
      );

      return {
        header: "Overall Probability Rating",
        content: worstProbabilityRating
          ? `${worstProbabilityRating} (${
              RATING_LABEL.ProbabilityRating[worstProbabilityRating]
            })`
          : "N/A",
      };
    });

    const nrOfScenarios = parentView.addComponent({
      component: HighlightCard,
      layoutProps: { xs: 3, viewname: "oe-dashboard" },
    });

    nrOfScenarios.mapState(({ currentOEReportPage }) => ({
      header: "# Scenarios",
      content: currentOEReportPage?.scenarios.length ?? 0,
    }));

    const heatMapTopRiskAssessment = parentView.addComponent({
      component: RiskMatrix,
      layoutProps: { xs: 6, viewname: "oe-dashboard" },
    });

    heatMapTopRiskAssessment.mapState(({ currentOERatings }) => {
      const worstCurrentRatings =
        currentOERatings?.worst?.current ?? ([] as WorstScenarioRating[]);

      const worstFinancialRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.financialRating)
          .filter(Boolean) as RatingField[]
      );
      const worstReputationalRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.reputationalRating)
          .filter(Boolean) as RatingField[]
      );
      return {
        entries:
          worstReputationalRating && worstFinancialRating
            ? [
                [
                  {
                    name: "",
                    xAxisField: worstFinancialRating,
                    yAxisField: worstReputationalRating,
                  },
                ],
              ]
            : [],
        title: "Heat Map (Top Risk Assessment)",
        xAxisLabel: "Financial Impact",
        yAxisLabel: "Reputational Impact",
      };
    });
    const heatMap1In20Years = parentView.addComponent({
      component: RiskMatrix,
      layoutProps: { xs: 6, viewname: "oe-dashboard" },
    });

    heatMap1In20Years.mapState(({ currentOEReportPage }) => {
      const { financialImpactRating, reputationalImpactRating } =
        currentOEReportPage?.oeStatus ?? ({} as FE_OEStatus);
      const hasRequiredValues =
        financialImpactRating != null && reputationalImpactRating != null;

      return {
        entries: hasRequiredValues
          ? [
              [
                {
                  name: "",
                  xAxisField: financialImpactRating,
                  yAxisField: reputationalImpactRating as CommonRatingValue,
                },
              ],
            ]
          : [],
        title: "Heat Map (1 in 20 years probability)",
        xAxisLabel: "Financial Impact",
        yAxisLabel: "Reputational Impact",
      };
    });

    const oeDashboardChangeYearHeadline = parentView.addComponent({
      component: OEReportSectionHeader<"edit">,
      layoutProps: { xs: 12, viewname: "oe-dashboard" },
    });

    oeDashboardChangeYearHeadline.mapState(({ year }) => {
      return {
        title: `Change ${year - 1} to ${year}`,
      };
    });

    const topRiskChangeCard = parentView.addComponent({
      component: HighlightCard,
      layoutProps: { xs: 3, viewname: "oe-dashboard" },
    });

    topRiskChangeCard.mapState(({ currentOEReportPage, year }) => {
      const topRiskLastYearValue =
        currentOEReportPage?.oeDashboardData?.[year - 1]?.isTopRisk;
      const topRiskLastYearValueText =
        topRiskLastYearValue === null || topRiskLastYearValue === undefined
          ? "N/A"
          : topRiskLastYearValue
            ? "Yes"
            : "No";

      const topRiskCurrentYearValue =
        currentOEReportPage?.oeDashboardData?.[year]?.isTopRisk;
      const topRiskCurrentYearValueText =
        topRiskCurrentYearValue === null ||
        topRiskCurrentYearValue === undefined
          ? "N/A"
          : topRiskCurrentYearValue
            ? "Yes"
            : "No";

      return {
        header: "Top Risk",
        chips: [
          {
            subtitle: `${year - 1}`,
            label: topRiskLastYearValueText,
            backgroundColor:
              topRiskLastYearValue === null ||
              topRiskLastYearValue === undefined
                ? "#C4C4C4"
                : "#2196F3",
            textColor: "#fff",
          },
          {
            subtitle: `${year}`,
            label: topRiskCurrentYearValueText,
            backgroundColor:
              topRiskCurrentYearValue === null ||
              topRiskCurrentYearValue === undefined
                ? "#C4C4C4"
                : "#2196F3",
            textColor: "#fff",
          },
        ],
      };
    });
    const overallProbabilityRatingChangeCard = parentView.addComponent({
      component: HighlightCard,
      layoutProps: { xs: 3, viewname: "oe-dashboard" },
    });

    overallProbabilityRatingChangeCard.mapState(
      ({ currentOEReportPage, year }) => {
        const overallProbRatingLastYearValue = currentOEReportPage
          ?.oeDashboardData?.[year - 1]
          ?.overallProbabilityRating as CommonRatingValue;

        const overallProbRatingLastYearText =
          overallProbRatingLastYearValue != null
            ? `(${
                RATING_LABEL.ProbabilityRating[
                  overallProbRatingLastYearValue as keyof typeof RATING_LABEL.ProbabilityRating
                ]
              })`
            : "";

        const overallProbRatingLastYearBackgroundColor =
          overallProbRatingLastYearValue
            ? getColorSchemeForRating(overallProbRatingLastYearValue)
                .colorScheme.chipColor
            : undefined;

        const overallProbRatingLastYearTextColor =
          overallProbRatingLastYearValue
            ? getColorSchemeForRating(overallProbRatingLastYearValue)
                .colorScheme.textColor
            : undefined;

        const overallProbRatingCurrentYearValue = currentOEReportPage
          ?.oeDashboardData?.[year]
          ?.overallProbabilityRating as CommonRatingValue;

        const overallProbRatingCurrentYearText =
          overallProbRatingCurrentYearValue != null
            ? `(${
                RATING_LABEL.ProbabilityRating[
                  overallProbRatingCurrentYearValue as keyof typeof RATING_LABEL.ProbabilityRating
                ]
              })`
            : "";

        const overallProbRatingCurrentYearBackgroundColor =
          overallProbRatingCurrentYearValue
            ? getColorSchemeForRating(overallProbRatingCurrentYearValue)
                .colorScheme.chipColor
            : undefined;

        const overallProbRatingCurrentYearTextColor =
          overallProbRatingCurrentYearValue
            ? getColorSchemeForRating(overallProbRatingCurrentYearValue)
                .colorScheme.textColor
            : undefined;

        return {
          header: "Overall Probability Rating",
          chips: [
            {
              subtitle: `${year - 1}`,
              label: `${overallProbRatingLastYearValue || "N/A"} ${overallProbRatingLastYearText}`,
              backgroundColor:
                overallProbRatingLastYearBackgroundColor || "#C4C4C4",
              textColor: overallProbRatingLastYearTextColor || "#fff",
            },
            {
              subtitle: `${year}`,
              label: `${overallProbRatingCurrentYearValue || "N/A"} ${overallProbRatingCurrentYearText}`,
              backgroundColor:
                overallProbRatingCurrentYearBackgroundColor || "#C4C4C4",
              textColor: overallProbRatingCurrentYearTextColor || "#fff",
            },
          ],
        };
      }
    );

    const overallFinanicalImpactRatingChangeCard = parentView.addComponent({
      component: HighlightCard,
      layoutProps: { xs: 3, viewname: "oe-dashboard" },
    });

    overallFinanicalImpactRatingChangeCard.mapState(
      ({ currentOEReportPage, year }) => {
        const overallFinancialRatingLastYearValue = currentOEReportPage
          ?.oeDashboardData?.[year - 1]
          ?.overallFinancialImpactRating as CommonRatingValue;

        const overallFinancialRatingLastYearText =
          overallFinancialRatingLastYearValue != null
            ? `(${
                RATING_LABEL.FinancialImpact[
                  overallFinancialRatingLastYearValue as keyof typeof RATING_LABEL.FinancialImpact
                ]
              })`
            : "";

        const overallFinancialRatingLastYearBackgroundColor =
          overallFinancialRatingLastYearValue
            ? getColorSchemeForRating(overallFinancialRatingLastYearValue)
                .colorScheme.chipColor
            : undefined;

        const overallFinancialRatingLastYearTextColor =
          overallFinancialRatingLastYearValue
            ? getColorSchemeForRating(overallFinancialRatingLastYearValue)
                .colorScheme.textColor
            : undefined;

        const overallFinancialRatingCurrentYearValue = currentOEReportPage
          ?.oeDashboardData?.[year]
          ?.overallFinancialImpactRating as CommonRatingValue;

        const overallFinancialRatingCurrentYearText =
          overallFinancialRatingCurrentYearValue != null
            ? `(${
                RATING_LABEL.FinancialImpact[
                  overallFinancialRatingCurrentYearValue as keyof typeof RATING_LABEL.FinancialImpact
                ]
              })`
            : "";

        const overallFinancialRatingCurrentYearBackgroundColor =
          overallFinancialRatingCurrentYearValue
            ? getColorSchemeForRating(overallFinancialRatingCurrentYearValue)
                .colorScheme.chipColor
            : undefined;

        const overallFinancialRatingCurrentYearTextColor =
          overallFinancialRatingCurrentYearValue
            ? getColorSchemeForRating(overallFinancialRatingCurrentYearValue)
                .colorScheme.textColor
            : undefined;

        return {
          header: "Overall Financial Impact Rating",
          chips: [
            {
              subtitle: `${year - 1}`,
              label: `${overallFinancialRatingLastYearValue || "N/A"} ${overallFinancialRatingLastYearText}`,
              backgroundColor:
                overallFinancialRatingLastYearBackgroundColor || "#C4C4C4",
              textColor: overallFinancialRatingLastYearTextColor || "#fff",
            },
            {
              subtitle: `${year}`,
              label: `${overallFinancialRatingCurrentYearValue || "N/A"} ${overallFinancialRatingCurrentYearText}`,
              backgroundColor:
                overallFinancialRatingCurrentYearBackgroundColor || "#C4C4C4",
              textColor: overallFinancialRatingCurrentYearTextColor || "#fff",
            },
          ],
        };
      }
    );

    const overallReputationalImpactRatingChangeCard = parentView.addComponent({
      component: HighlightCard,
      layoutProps: { xs: 3, viewname: "oe-dashboard" },
    });

    overallReputationalImpactRatingChangeCard.mapState(
      ({ currentOEReportPage, year }) => {
        const overallReputationalRatingLastYearValue = currentOEReportPage
          ?.oeDashboardData?.[year - 1]
          ?.overallReputationalImpactRating as CommonRatingValue;

        const overallReputationalRatingLastYearText =
          overallReputationalRatingLastYearValue != null
            ? `(${
                RATING_LABEL.ReputationalImpact[
                  overallReputationalRatingLastYearValue as keyof typeof RATING_LABEL.ReputationalImpact
                ]
              })`
            : "";

        const overallReputationalRatingLastYearBackgroundColor =
          overallReputationalRatingLastYearValue
            ? getColorSchemeForRating(overallReputationalRatingLastYearValue)
                .colorScheme.chipColor
            : undefined;

        const overallReputationalRatingLastYearTextColor =
          overallReputationalRatingLastYearValue
            ? getColorSchemeForRating(overallReputationalRatingLastYearValue)
                .colorScheme.textColor
            : undefined;

        const overallReputationalRatingCurrentYearValue = currentOEReportPage
          ?.oeDashboardData?.[year]
          ?.overallReputationalImpactRating as CommonRatingValue;

        const overallReputationalRatingCurrentYearText =
          overallReputationalRatingCurrentYearValue != null
            ? `(${
                RATING_LABEL.ReputationalImpact[
                  overallReputationalRatingCurrentYearValue as keyof typeof RATING_LABEL.ReputationalImpact
                ]
              })`
            : "";

        const overallReputationalRatingCurrentYearBackgroundColor =
          overallReputationalRatingCurrentYearValue
            ? getColorSchemeForRating(overallReputationalRatingCurrentYearValue)
                .colorScheme.chipColor
            : undefined;

        const overallReputationalRatingCurrentYearTextColor =
          overallReputationalRatingCurrentYearValue
            ? getColorSchemeForRating(overallReputationalRatingCurrentYearValue)
                .colorScheme.textColor
            : undefined;

        return {
          header: "Overall Reputational Impact Rating",
          chips: [
            {
              subtitle: `${year - 1}`,
              label: `${overallReputationalRatingLastYearValue || "N/A"} ${overallReputationalRatingLastYearText}`,
              backgroundColor:
                overallReputationalRatingLastYearBackgroundColor || "#C4C4C4",
              textColor: overallReputationalRatingLastYearTextColor || "#fff",
            },
            {
              subtitle: `${year}`,
              label: `${overallReputationalRatingCurrentYearValue || "N/A"} ${overallReputationalRatingCurrentYearText}`,
              backgroundColor:
                overallReputationalRatingCurrentYearBackgroundColor ||
                "#C4C4C4",
              textColor:
                overallReputationalRatingCurrentYearTextColor || "#fff",
            },
          ],
        };
      }
    );

    const heatMapTopRiskAssessmentChange = parentView.addComponent({
      component: RiskMatrix,
      layoutProps: { xs: 6, viewname: "oe-dashboard" },
    });

    heatMapTopRiskAssessmentChange.mapState(({ currentOEReportPage, year }) => {
      const financialDataLastYear =
        (currentOEReportPage?.oeDashboardData?.[year - 1]?.topRiskAssignment
          .financialData as CommonRatingValue) || null;

      const reputationalDataLastYear =
        (currentOEReportPage?.oeDashboardData?.[year - 1]?.topRiskAssignment
          .reputationalData as CommonRatingValue) || null;

      const financialDataCurrentYear =
        (currentOEReportPage?.oeDashboardData?.[year]?.topRiskAssignment
          .financialData as CommonRatingValue) || null;

      const reputationalDataCurrentYear =
        (currentOEReportPage?.oeDashboardData?.[year]?.topRiskAssignment
          .reputationalData as CommonRatingValue) || null;

      const entries = [];

      // Check conditions and add data for last year if both values are not null
      if (financialDataLastYear !== null && reputationalDataLastYear !== null) {
        entries.push([
          {
            name: `${year - 1} Data`,
            xAxisField: financialDataLastYear,
            yAxisField: reputationalDataLastYear,
            backgroundColor: "#C4C4C4",
          },
        ]);
      }

      // Check conditions and add data for currently selected year if both values are not null
      if (
        financialDataCurrentYear !== null &&
        reputationalDataCurrentYear !== null
      ) {
        entries.push([
          {
            name: `${year} Data`,
            xAxisField: financialDataCurrentYear,
            yAxisField: reputationalDataCurrentYear,
          },
        ]);
      }

      return {
        entries,
        legendPoint: {
          label: `${year}`,
          circleText: "",
        },
        secondaryLegendPoint: {
          label: `${year - 1}`,
          circleText: "",
          circleColor: "#C4C4C4",
        },
        title: "Heat Map (Top Risk Assessment)",
        xAxisLabel: "Financial Impact",
        yAxisLabel: "Reputational Impact",
      };
    });

    const heatMap1In20YearsChange = parentView.addComponent({
      component: RiskMatrix,
      layoutProps: { xs: 6, viewname: "oe-dashboard" },
    });

    heatMap1In20YearsChange.mapState(
      ({ currentOEProbabilityRatingOptions, year }) => {
        const { financialImpact, reputationalImpact } =
          currentOEProbabilityRatingOptions ??
          ({} as OneInTwentyYearsProbabilityRatingOptions);

        const financialImpactValue = financialImpact?.find(
          ({ selected }) => selected
        )?.value;

        const reputationalImpactValue = reputationalImpact?.find(
          ({ selected }) => selected
        )?.value;

        return {
          entries:
            financialImpactValue && reputationalImpactValue
              ? [
                  [
                    {
                      name: "",
                      xAxisField: financialImpactValue,
                      yAxisField: reputationalImpactValue,
                    },
                  ],
                ]
              : [],
          legendPoint: {
            label: `${year}`,
            circleText: "",
          },
          secondaryLegendPoint: {
            label: `${year - 1}`,
            circleText: "",
            circleColor: "#C4C4C4",
          },
          title: "Heat Map (1 in 20 years probability)",
          xAxisLabel: "Financial Impact",
          yAxisLabel: "Reputational Impact",
        };
      }
    );

    const detailsScenariosFinancialImpactHeadline = parentView.addComponent({
      component: OEReportSectionHeader<"edit">,
      layoutProps: { xs: 12, viewname: "oe-dashboard" },
    });

    detailsScenariosFinancialImpactHeadline.mapState(() => {
      return {
        title: "Details Scenarios - Financial Impact",
      };
    });

    const detailsScenariosFinancialImpactRiskMatrix = parentView.addComponent({
      component: RiskMatrix,
      layoutProps: { xs: 6, viewname: "oe-dashboard" },
    });

    detailsScenariosFinancialImpactRiskMatrix.mapState((state) => {
      const data = generateFilteredRiskEntries(state, "current") ?? [];

      return {
        entries:
          data && data.length > 0
            ? groupEntriesByCommonAxisValue(
                data.map(({ scenarioName, financialImpact, probRating }) => ({
                  name: scenarioName,
                  xAxisField: probRating as CommonRatingValue,
                  yAxisField: financialImpact as CommonRatingValue,
                  isEntryVisibleOnTooltip: true,
                }))
              )
            : [],
        legendPoint: { label: `#Scenarios` },
        showAmount: true,
        title: "Heat Map (Top Risk Assessment)",
        xAxisLabel: "Probability",
        yAxisLabel: "Financial Impact",
      };
    });

    const progressCardFinancialImpact = parentView.addComponent({
      component: InformationCard,
      layoutProps: { xs: 6, viewname: "oe-dashboard" },
    });
    progressCardFinancialImpact.mapState(({ currentOEReportPage, year }) => {
      const progressFinancialImpactValues =
        currentOEReportPage?.oeDashboardData?.[year]
          ?.rankingAbsoluteFinancialImpact ?? [];

      const formatToMillions = (value: number) =>
        (Math.floor((value / 1_000_000) * 10) / 10).toFixed(2);

      const maxValue = Math.max(
        ...progressFinancialImpactValues.map((item) => item.value || 0),
        1
      );

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        metricsProps: {
          sx: {
            marginTop: 2.4,
          },
        },
        title: "Ranking Absolute Financial Impact (in € m)",
        progressBars: progressFinancialImpactValues.map((item, index) => ({
          title: item.name || `Scenario ${index + 1}`,
          progress: ((item.value ?? 0) / maxValue) * 100,
          actualValue: `${formatToMillions(item.value || 0)} m`,
        })),
      };
    });

    const detailsScenariosReputationalImpactHeadline = parentView.addComponent({
      component: OEReportSectionHeader<"edit">,
      layoutProps: { xs: 12, viewname: "oe-dashboard" },
    });

    detailsScenariosReputationalImpactHeadline.mapState(() => {
      return {
        title: "Default Scenarios - Reputational Impact",
      };
    });

    const heatMapReputationalImpact = parentView.addComponent({
      component: RiskMatrix,
      layoutProps: { xs: 6, viewname: "oe-dashboard" },
    });

    heatMapReputationalImpact.mapState((state) => {
      const data = getRepImpactAndProbRatings(state, "residual") ?? [];

      return {
        entries:
          data && data.length > 0
            ? groupEntriesByCommonAxisValue(
                data.map(
                  ({
                    scenarioName,
                    reputationalImpactRating,
                    probabilityRating,
                  }) => ({
                    name: scenarioName,
                    xAxisField: probabilityRating as CommonRatingValue,
                    yAxisField: reputationalImpactRating as CommonRatingValue,
                    isEntryVisibleOnTooltip: true,
                  })
                )
              )
            : [],
        legendPoint: { label: `#Scenarios` },
        showAmount: true,
        title: "Heat Map (Top Risk Assessment)",
        xAxisLabel: "Probability",
        yAxisLabel: "Reputational Impact",
      };
    });

    const progressCardReputationalImpact = parentView.addComponent({
      component: InformationCard,
      layoutProps: { xs: 6, viewname: "oe-dashboard" },
    });

    progressCardReputationalImpact.mapState(({ currentOEReportPage, year }) => {
      const progressReputationalImpactValues =
        currentOEReportPage?.oeDashboardData?.[year]
          ?.rankingReputationalImpactRating ?? [];

      const maxValue = Math.max(
        ...progressReputationalImpactValues.map((item) => item.value || 0),
        1
      );

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        metricsProps: {
          sx: {
            marginTop: 2.4,
          },
        },
        title: "Ranking Reputational Impact Rating",
        progressBars: progressReputationalImpactValues.map((item, index) => ({
          title: item.name || `Scenario ${index + 1}`,
          progress: ((item.value ?? 0) / maxValue) * 100,
          actualValue: item.value != null ? item.value : "N/A",
        })),
      };
    });
  }

  private async fetchOEReportPage(
    app: App<TracyAppState>,
    oeId: string | number
  ): Promise<void> {
    const { year } = app.getAppState();

    const [
      currentOEReportPage,
      currentOERatings,
      currentScenariosFormValues,
      currentOEStatusComments,
      currentOEProbabilityRatingOptions,
      permissions,
    ] = await Promise.all([
      fetchOEReportPage({
        id: +oeId,
        year,
      }),
      fetchOERatings({ id: +oeId, year }),
      fetchScenariosFormValues({ id: +oeId, year }),
      fetchOEStatusComments({ id: +oeId, year }),
      fetchOEProbabilityRating({ id: +oeId, year }),
      fetchPermissions(),
    ]);

    if (!currentOEReportPage) return;

    const isAdminWithWriteAccess = permissions?.some(
      ({ role, readonly }) => role === "admin" && !readonly
    );

    const isTestOE = currentOEReportPage?.oe?.isTestOE ?? false;

    if (isTestOE && !isAdminWithWriteAccess) {
      return;
    }

    app.patchAppState({
      currentOEProbabilityRatingOptions,
      currentOERatings,
      currentOEReportPage,
      currentOEReportPageYear: app.getAppState().year,
      currentOEStatusComments,
      currentScenariosFormValues,
      permissions,
    });
  }

  private navigateBackToReport(app: App<TracyAppState>) {
    app.navigate(
      generatePath(`${APP_ROUTES.oeReportAssessment}`, {
        oeId: `${app.getAppState().currentOEReportPage?.oe?.id ?? ""}`,
      })
    );
  }
}
