/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, Typography, useTheme } from "@mui/material";
import { PimoClassComponent, PimoReactComponent } from "@pimo/pimo-app-builder";
import { useEffect, useState } from "react";

export interface KanbanItem {
  id: string;
}

export type KanbanBoardDefinition = {
  component: PimoReactComponent<any, any>;
};

export type DerivePimoReactComponentProps<Component> =
  Component extends PimoReactComponent<infer Props, any> ? Props : never;

type DeriveDefinition<Component> =
  Component extends PimoKanbanBoard<infer Definition> ? Definition : never;

export type DeriveKanbanBoardProps<Component> = {
  columns: {
    kanbanItemProps: (DerivePimoReactComponentProps<
      DeriveDefinition<Component>[keyof DeriveDefinition<Component>]
    > &
      KanbanItem)[];
    columnTitle: string;
    isStatic?: boolean;
    columnWidth?: number | string;
  }[];
};

class PimoKanbanBoard<
  Definition extends KanbanBoardDefinition,
> extends PimoClassComponent<any> {
  constructor(private kanbanItem: Definition) {
    super();
  }

  render(): PimoReactComponent<any, any, any> {
    return ({
      columns,
      fireEvent,
    }: {
      fireEvent?: (
        eventName: "action:move-item",
        payload: {
          item: string;
          newColumn: string;
        }
      ) => void;
      columns: {
        kanbanItemProps: (PimoKanbanBoard<Definition>["kanbanItem"] &
          KanbanItem)[];
        columnTitle: string;
        isStatic?: boolean;
        columnWidth?: number | string;
      }[];
    }) => {
      const theme = useTheme();
      const [boardColumns, setBoardColumns] = useState(columns);

      useEffect(() => {
        setBoardColumns(columns);
      }, [columns]);

      const onDragStart = (
        event: React.DragEvent,
        draggedItemId: string, // Use item id instead of full object
        startColumn: number
      ) => {
        event.dataTransfer.setData("draggedItemId", draggedItemId); // Store item id
        event.dataTransfer.setData(
          "startColIndex",
          JSON.stringify(startColumn)
        );
      };

      const onDrop = (event: React.DragEvent, endColIndex: number) => {
        if (boardColumns[endColIndex].isStatic) {
          return;
        }

        const draggedItemId = event.dataTransfer.getData("draggedItemId");
        const startColIndex = parseInt(
          event.dataTransfer.getData("startColIndex")
        );

        if (startColIndex !== endColIndex) {
          const newColumns = [...boardColumns];

          // Find and remove the dragged item from the start column
          const draggedItemIndex = newColumns[
            startColIndex
          ].kanbanItemProps.findIndex((item) => item.id === draggedItemId);
          const draggedItem =
            newColumns[startColIndex].kanbanItemProps[draggedItemIndex];

          newColumns[startColIndex].kanbanItemProps.splice(draggedItemIndex, 1);
          newColumns[endColIndex].kanbanItemProps.push(draggedItem);

          setBoardColumns(newColumns);

          fireEvent?.("action:move-item", {
            item: draggedItem.id,
            newColumn: newColumns[endColIndex].columnTitle,
          });
        }
      };

      const onDragOver = (event: React.DragEvent) => {
        event.preventDefault();
      };

      return (
        <Box
          display="flex"
          gap={2}
          padding={2}
          sx={{
            background: "#ffffff",
            borderRadius: "12px",
            height: "80vh",
            overflowX: "scroll",
          }}
        >
          {boardColumns.map((col, colIndex) => (
            <Box
              key={col.columnTitle}
              onDragOver={onDragOver}
              onDrop={(e) => onDrop(e, colIndex)}
              sx={{
                flex: 1,
                minWidth: col.columnWidth || "250px",
                maxWidth: col.columnWidth || "250px",
                borderRadius: "12px",
                flexDirection: "column",
                background: "#ffffff",
                height: "98%",
              }}
            >
              <Box textAlign="center" marginBottom={1}>
                <Typography variant="body2" fontWeight={500} fontSize="12px">
                  {col.columnTitle} ({col.kanbanItemProps.length})
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: "12px",
                  padding: 2,
                  height: "100%",
                  flex: 1,
                  overflowY: "auto",
                }}
              >
                {col.kanbanItemProps.map(
                  (
                    props: {
                      component: PimoReactComponent<any, any>;
                      id: string;
                    },
                    index
                  ) => {
                    const ComponentForColumn =
                      this.kanbanItem?.component ?? (() => <></>);

                    return (
                      <Card
                        key={index}
                        onDragStart={
                          col.isStatic
                            ? undefined
                            : (e) => onDragStart(e, props.id, colIndex)
                        }
                        sx={{
                          borderRadius: "12px",
                          marginBottom: 2,
                          p: 1,
                          boxShadow: "none",
                          "&:hover": {
                            backgroundColor: "rgb(238, 235, 235)",
                          },

                          cursor: col.isStatic ? "not-allowed" : "pointer",
                        }}
                        draggable={!col.isStatic}
                      >
                        <ComponentForColumn
                          {...props}
                          cardProps={{ sx: { padding: 0 } }}
                        />
                      </Card>
                    );
                  }
                )}
              </Box>
            </Box>
          ))}
        </Box>
      );
    };
  }
}

export default PimoKanbanBoard;
