import { Box, Divider, styled, Typography, useTheme } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useCallback } from "react";
import { CommonRatingValue, FINANCIAL_RATING } from "tracy-types";

import { TracyTooltip } from "../tracy-tooltip";

export interface TopRiskMatrixProps {
  title: string;
  /** the entries that will populate the matrix */
  entries: {
    /** text to be displayed in the badge */
    name: string;
    /** Y coordinate */
    xAxisField: CommonRatingValue;
    /** X coordinate */
    yAxisField: CommonRatingValue;
    /** (optional) need for redirection */
    findingId?: string;
    /** should the entry be seen on the tooltip */
    isEntryVisibleOnTooltip?: boolean;
    /** check if its top risk */
    isTopRisk?: boolean;
    /** (optional) on click */
    onClick?: () => void;
  }[][];
}

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  marginBottom: theme.spacing(1),
  borderRadius: "12px",
  width: "100%",
}));

const Title = styled(Box)(() => ({
  fontWeight: 500,
  fontSize: "1.25rem",
}));

const GraphContainer = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "0.2fr 7fr",
  width: "95%",
  minHeight: 500,
  margin: 10,
}));

const YLabel = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transform: "rotate(-90deg)",
  fontSize: "20px",
  width: 60,
  fontWeight: 500,
  whiteSpace: "nowrap",
}));

const XLabel = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  verticalAlign: "middle",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "20px",
  fontWeight: 500,
}));

const XTicks = styled(Box)(() => ({
  verticalAlign: "middle",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const YTicks = styled(Box)(() => ({
  verticalAlign: "middle",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
}));
const HeatMap = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridTemplateRows: "repeat(5, 1fr)",
  backgroundColor: "white",
}));

export const TopRiskMatrix: PimoReactComponent<TopRiskMatrixProps> = ({
  entries,
  title,
}) => {
  const theme = useTheme();

  /** function to generate the matrix squares, and respecitvely the badges that will populate them */
  const generateBoxes = useCallback(
    (
      items: {
        name: string;
        yAxisField: number;
        isEntryVisibleOnTooltip?: boolean;
        xAxisField: number;
        isTopRisk?: boolean;
        onClick?: () => void;
      }[][]
    ) => {
      const listItems = [];
      for (let i = 0; i < 5; i++) {
        for (let j = 0; j < 5; j++) {
          listItems.push(
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                zIndex: 0,
                maxWidth: "100%",
                minWidth: "100%",
                maxHeight: 100,
                ...setBackgroundColor(i, j),
                borderColor: "red",
                justifyContent: "center",
                alignItems: "center",
              }}
              key={`cell${i} - ${j}`}
            >
              {items
                .filter(
                  (item) => item[0].xAxisField === j && item[0].yAxisField === i
                )
                .map((item) => (
                  <Box
                    key={`badge-container-${item[0].name}`}
                    id={`badge-container${item[0].name}`}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TracyTooltip
                      title={
                        item.filter((it) => it.isEntryVisibleOnTooltip).length >
                        0
                          ? item
                              .filter((it) => it.isEntryVisibleOnTooltip)
                              .map((a, index) => `${index + 1}. ${a.name}\n`)
                          : undefined
                      }
                    >
                      <Box
                        id={`point${item[0].name}`}
                        sx={{
                          backgroundColor: "#2f8ee7",
                          margin: theme.spacing(0.5),
                          border: "solid",
                          width: `${35 + item.length * 2}px`,
                          height: `${35 + item.length * 2}px`,
                          maxHeight: "80px",
                          maxWidth: "80px",
                          fontSize: "1.5rem",
                          lineHeight: `${35 + item.length * 1.5}px`,
                          borderRadius: "100%",
                          textAlign: "center",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                        key={`${item[0].xAxisField - item[0].yAxisField}`}
                      >
                        {item.length}
                      </Box>
                    </TracyTooltip>
                  </Box>
                ))}
            </Box>
          );
        }
      }
      return listItems;
    },
    [theme]
  );

  return (
    <Container data-testid="heat-map-container">
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Title>{title}</Title>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography fontWeight={500} fontSize={18}>
            Legend:
          </Typography>
          <Box
            sx={{
              backgroundColor: "#2f8ee7",
              margin: theme.spacing(0.2),
              border: "2px dashed",
              borderColor: "red",
              width: "100px",
              height: "30px",
              maxHeight: "80px",
              maxWidth: "100px",
              fontSize: "1rem",
              lineHeight: "25px",
              textAlign: "center",
              verticalAlign: "middle",
              background: "white",
              color: "black",
            }}
          >
            Top Risk
          </Box>
          <Box
            sx={{
              backgroundColor: "#2f8ee7",
              margin: theme.spacing(0.2),
              border: "solid",
              width: "30px",
              height: "30px",
              maxHeight: "80px",
              maxWidth: "80px",
              fontSize: "1.5rem",
              lineHeight: "25px",
              borderRadius: "100%",
              textAlign: "center",
              verticalAlign: "middle",
              color: "white",
            }}
          >
            #
          </Box>
          <Typography fontWeight={400} fontSize={18} ml={-2}>
            #OEs
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
        }}
      />
      <GraphContainer sx={{ py: 2 }}>
        <YLabel>Overall Impact Severity</YLabel>
        <Box
          sx={{
            display: "grid",
            gridTemplateRows: "7fr 0.5fr",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "0.25fr 6fr",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateRows: "repeat(6, 1fr)",
                  height: "100%",
                }}
              >
                {[...FINANCIAL_RATING]
                  .sort((a, b) => b - a)
                  .map((severity) => (
                    <YTicks key={"ytick" + severity.toString()}>
                      {severity}
                    </YTicks>
                  ))}
                <Box sx={{ verticalAlign: "middle" }} />
              </Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateRows: "6fr 1fr",
              }}
            >
              <HeatMap id="RiskMatrix">
                {generateBoxes(
                  entries.map((x) =>
                    x.map((col) => ({
                      ...col,
                      xAxisField: col.xAxisField - 1,
                      yAxisField: 5 - col.yAxisField,
                    }))
                  )
                )}
              </HeatMap>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(5, 1fr)",
                }}
              >
                {FINANCIAL_RATING.map((probability) => (
                  <XTicks key={"xtick" + probability.toString()}>
                    {probability}
                  </XTicks>
                ))}
              </Box>
            </Box>
          </Box>
          <XLabel>Overall Probability Rating</XLabel>
        </Box>
      </GraphContainer>
    </Container>
  );
};

function setBackgroundColor(
  i: number,
  j: number
): {
  backgroundColor: string;
  borderWidth?: string;
  borderTopStyle?: string;
  borderBottomStyle?: string;
  borderRightStyle?: string;
  borderLeftStyle?: string;
} {
  const pattern = {
    backgroundColor: "white",
  };

  if (i === 0) {
    return {
      ...pattern,
      borderWidth: "2px",
      borderTopStyle: "dashed",
      borderRightStyle: j === 4 ? "dashed" : "none",
      borderLeftStyle: j === 0 ? "dashed" : "none",
    };
  }

  if (i == 1 && j == 1) {
    return {
      ...pattern,
      borderWidth: "2px",
      borderTopStyle: "dashed",
    };
  }
  if (i == 1 && j == 0) {
    return {
      ...pattern,
      borderWidth: "2px",
      borderTopStyle: "dashed",
    };
  }

  if (i === 1 && j > 1) {
    return {
      ...pattern,
      borderWidth: "2px",
      borderTopStyle: "none",
      borderBottomStyle: j === 3 || j === 2 ? "dashed" : "none",
      borderRightStyle: j === 4 ? "dashed" : "none",
      borderLeftStyle: j === 2 ? "dashed" : "none",
    };
  }

  if (i === 2 && j === 4) {
    return {
      ...pattern,
      borderWidth: "2px",
      borderBottomStyle: "dashed",
      borderLeftStyle: "dashed",
      borderRightStyle: "dashed",
    };
  }
  return pattern;
}
