import { ManageClusterEventPayload, OECluster } from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function fetchOEClusters(): Promise<OECluster[]> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-cluster`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as OECluster[];
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function storeCluster(
  payload: ManageClusterEventPayload
): Promise<OECluster | undefined> {
  try {
    const { name } = payload;

    const response = await fetch(`${STRAPI_URL}/api/bff-cluster`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
      }),
    });

    if (!response.ok) {
      throw new Error(`Create cluster failed with status ${response.status}.`);
    }
  } catch (error) {
    console.error("Error storing cluster:", error);
    return;
  }
}

export async function updateCluster(
  id: number,
  payload: ManageClusterEventPayload
): Promise<OECluster | undefined> {
  try {
    const { name } = payload;

    const response = await fetch(`${STRAPI_URL}/api/bff-cluster/${id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
      }),
    });

    if (!response.ok) {
      throw new Error(`Update cluster failed with status ${response.status}.`);
    }

    return (await response.json()) as OECluster;
  } catch (error) {
    console.error("Error updating cluster:", error);
    return;
  }
}

export async function deleteCluster(id: number): Promise<void> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-cluster/${id}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Delete cluster failed with status ${response.status}.`);
    }
  } catch (error) {
    console.error("Error deleting cluster:", error);
  }
}
