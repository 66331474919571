import { OEQAComment } from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function createOeQaComment({
  payload,
  id,
  year,
}: {
  payload: Partial<OEQAComment>;
  id: number;
  year: number;
}): Promise<OEQAComment> {
  const res = await fetch(
    `${STRAPI_URL}/api/bff/oe-qa-comments/${id}/${year}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ data: payload }),
    }
  );

  return (await res.json()) as OEQAComment;
}
