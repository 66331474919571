import { Send } from "@mui/icons-material";
import { Box, Divider, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { OEQAComment } from "tracy-types";
import { formatDateTime } from "tracy-utils";

import { theme } from "../../theme";

type CommentSectionProps = {
  comments: OEQAComment[];
  onSubmitComment: (comment: string) => void;
};

export const CommentsSection = ({
  comments,
  onSubmitComment,
}: CommentSectionProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [commentFieldValue, setCommentFieldValue] = useState<string>();

  const submitComment = async () => {
    if (!commentFieldValue?.trim()) return;

    setCommentFieldValue("");
    if (inputRef.current) {
      inputRef.current.value = "";
    }

    onSubmitComment(commentFieldValue);
  };

  return (
    <>
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "14px",
          mb: 1,
        }}
      >
        Comments
      </Typography>

      <Box>
        <TextField
          sx={{
            height: "40px",
            width: "100%",
            border: "none",
            mb: 3,
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
          }}
          InputProps={{
            style: {
              height: "100%",
              minWidth: 150,
              borderRadius: "6px",
              border: "none",
            },
            endAdornment: (
              <Send
                sx={{
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                }}
                onClick={submitComment}
              />
            ),
          }}
          inputRef={inputRef}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
            event.key === "Enter" && submitComment()
          }
          onChange={(event) => {
            setCommentFieldValue(event.target.value);
          }}
        ></TextField>
      </Box>
      <Box
        sx={{
          mb: 4,
        }}
      >
        {comments.map((comment: OEQAComment) => (
          <div key={comment.id}>
            <Typography
              sx={{
                color: "#767676",
                fontSize: "12px",
                mb: 1,
              }}
            >
              {`${comment.author} on ${comment.createdAt ? formatDateTime(comment.createdAt) : ""}`}
            </Typography>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                lineHeight: "20px",
                mb: 1.5,
                mt: 0,
                p: 0,
                whiteSpace: "pre-line",
              }}
            >
              {comment.comment}
            </Box>
            <Divider
              sx={{
                bgcolor: theme.palette.secondary.main,
                height: "1px",
                mb: 2.5,
                borderWidth: 0,
              }}
            />
          </div>
        ))}
      </Box>
    </>
  );
};
